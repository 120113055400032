import React from "react";
import Activity from "./Activity";
import { Drawer } from "antd";

const ActivityDrawer = ({ openFeed, setOpenFeed }) => {
  const onClose = () => {
    setOpenFeed(false);
  };
  return (
    <div>
      <Drawer
        style={{
          borderRadius: " 20px 0px 0px 20px ",
          backgroundColor: "white",
        }}
        placement={"right"}
        closable={false}
        onClose={onClose}
        open={openFeed}
        height={100}
      >
        <Activity />
      </Drawer>
    </div>
  );
};

export default ActivityDrawer;
