import React, { useEffect, useState } from "react";
import ButtonData from "../../../components/ui/button";
import { Base_Route, profileTabs } from "../../../helper/constant";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form, Radio, Switch, Upload } from "antd";
import FormModal from "../../../components/modals/FormModal";
import ChangePassword from "../../../components/ui/ChangePassword";
import toastr from "toastr";
import axios from "../../../helper/api/api_instance";
import DeleteAccoutModal from "../../../components/modals/DeleteAccoutModal";
import ProfileDetail from "./components/profileDetail";
import BillingPayment from "./components/billingPayment";
import UpdateCardModal from "./components/updateCardModal";
import instance from "../../../helper/api/api_instance_node";
export const Profile = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [abilities, setAbilities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [state, setState] = useState({});
  const [abilityArr, setAbilityArr] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [favourite, setFavourite] = useState("profile");
  const [searchParams] = useSearchParams();
  const is_manual_subscribe = localStorage.getItem('is_manual_subscribe');
  const UserProfile = JSON.parse(
    localStorage.getItem("UserProfile")
  ).UserProfile;

  useEffect(() => {
    if (searchParams.get("k") === "update") {
      setFavourite("billing");
    }
    getAllAbilities();
    setState({ imageUrl: UserProfile?.profile_pic });
    setFileList([...fileList]);
    setAbilityArr(UserProfile?.disability_id.split(","));
    form.setFieldsValue({
      userName: UserProfile?.user_name,
    });
  }, []);

  const getAllAbilities = () => {
    const fd = new FormData();
    fd.append("user_id", UserProfile?.user_id);
    axios
      .post(`ability_list`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setAbilities(res?.data?.data);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const deleteAccountHandler = () => {
    axios
      .post(`user_delete_account`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          localStorage.clear();

          navigate(`${Base_Route}`);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    getBase64(newFileList.slice(-1)[0]?.originFileObj, (imageUrl) =>
      setState({
        imageUrl,
      })
    );
  };

  const props = {
    action: "",
    onChange: handleChange,
    multiple: false,
  };

  const onChange = (checked, val) => {
    if (checked) {
      abilityArr.push(val.toString());
    } else {
      abilityArr.pop(val.toString());
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    const fd = new FormData();
    fd.append("user_name", values?.userName);
    fd.append("disability_id", abilityArr?.toString());
    if (fileList?.length > 0) {
      fd.append(
        "profile_pic",
        fileList?.length === 0 &&
          typeof fileList[fileList.length - 1]?.imageUrl === "string"
          ? ""
          : fileList[fileList.length - 1].originFileObj
      );
    }

    fd.append("user_entity_id", UserProfile?.user_id);

    axios
      .post("edit_user", fd)
      .then(async (res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          navigate(`${Base_Route}/trufit/athlete-dashboard`);
          localStorage.setItem(
            "UserProfile",
            JSON.stringify({
              UserProfile: res.data.data,
            })
          );
          setLoading(false);
        } else {
          // toastr.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        // toastr.error(error.message);
        setLoading(false);
      });
  };

  const updatePaymentMethod = () => {
    instance
    .get(`/users/subscriptions/updatePaymentMethod`)
    .then((res) => {
      if (res?.status === 200) { 
       return (window.location.href = res?.data?.data?.url);
      }
    })
    .catch((error) => {
      toastr.error(error);
    });
  }


  return (
    <>
      <div className="mt-4 mx-3">
        <div className="d-flex justify-content-between align-items-center">
          <p className="activity">User Profile</p>
          <div className="d-flex">
          <ButtonData onClick={() => setVisible(true)} type="mx-2 filled-blue-btn">
            Change Password
          </ButtonData>
          {is_manual_subscribe !== "true" && (
          <ButtonData type="border-blue-btn" onClick={() => updatePaymentMethod()}>
          Manage Transactions
          </ButtonData>
          )}
          </div>
        </div>
            <ProfileDetail
              onFinish={onFinish}
              state={state}
              form={form}
              props={props}
              fileList={fileList}
              abilities={abilities}
              UserProfile={UserProfile}
              onChange={onChange}
              loading={loading}
              setDeleteModal={setDeleteModal}
            />
      </div>
      <FormModal
        visible={visible}
        onCancel={() => setVisible(false)}
        content={<ChangePassword handleCancel={() => setVisible(false)} />}
      />
      <FormModal
        visible={deleteModal}
        title={"Delete Account"}
        onCancel={() => setDeleteModal(false)}
        content={
          <DeleteAccoutModal
            deleteAccountHandler={deleteAccountHandler}
            setDeleteModal={setDeleteModal}
            deleteModal={deleteModal}
          />
        }
      />
    </>
  );
};
