import React from "react";
import { useLocation } from "react-router";
import Detail from '../../../components/ui/ExerciseDetail'
const ExerciseDetail = () => {
  const location = useLocation();
  let { id } = location.state;
  return (
   <Detail
   id={id}
   type="exercise"
   />  
  );
};

export default ExerciseDetail;
