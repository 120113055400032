import { Input } from 'antd'
import React from 'react'

const PasswordInput = ({disabled, status, addonAfter, addonBefore, defaultValue, placeholder, name, type, value, onChange, prefix, suffix, className, dir}) => {
  return (
    <Input.Password 
    status={status}
    dir={dir}
    disabled={disabled}
    addonAfter={addonAfter}
    addonBefore={addonBefore}
    defaultValue={defaultValue}
    prefix={prefix}
    suffix={suffix}
    placeholder={placeholder} 
    className={ addonBefore || addonAfter ? "" : `inputfield ${className}`} 
    name={name} 
    type={type} 
    value={value} 
    onChange={onChange}
    />
  )
}

export default PasswordInput