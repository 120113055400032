import React from "react";
import BlueLeftArrow from "../../assets/images/icons/icn_arrow_right_blue.png";
import { Typography } from "antd";
import ModalCross from "../../assets/images/icons/icn_close.png";
import dayjs from "dayjs";
const { Paragraph } = Typography;

const ProfileWorkout = ({
  title,
  id,
  description,
  date,
  arrow,
  workout,
  assignId,
  viewDetail,
  type,
  removeExercise,
  getWorkouts,
}) => {
  return (
    <>
      <div
        className="profile-workout mt-4 cursor-pointer"
        onClick={() => {
          type !== "workout_detail"
            ? viewDetail(id, assignId)
            : getWorkouts(id);
        }}
      >
        <div className="d-flex justify-content-between">
          <p className="exercise-heading">{title}</p>
          {type === "exercise" && (
            <img
              src={ModalCross}
              alt="modal-cross"
              style={{ height: "25px", width: "25px" }}
              onClick={() => removeExercise(id)}
            />
          )}
          {date !== undefined && (
            <p className="completed-on">
              {workout === "Assigned_Workouts" ? "Assigned on" : "Completed on"}{" "}
              <span>{dayjs(date).format("DD MMM YYYY")}</span>
            </p>
          )}
        </div>
        <div className="d-flex justify-content-between">
          <Paragraph
            className="exercise-desc"
            ellipsis={{
              rows: 4,
              expandable: false,
            }}
          >
            {description}
          </Paragraph>
          <div
            style={{ width: "30%", display: arrow ? "flex" : "none" }}
            className="justify-content-end"
          >
            <img
              src={BlueLeftArrow}
              alt="BlueLeftArrow"
              style={{ height: "20px", cursor: "pointer" }}
              onClick={() => viewDetail(id, assignId)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileWorkout;
