import React from "react";

const Pill = ({ text }) => {
    return (
        <div
            style={{
                backgroundColor: "#E2F4FF", borderRadius: "30px",
                paddingLeft: "10px", paddingRight: "10px",
                paddingBottom: "2px", paddingTop: "1px",
                marginRight: "5px", marginBottom: "10px"
            }}
        >
            <span
                
                style={{
                    width: "fit-content",
                    padding: "0px",
                    fontFamily: "poppins",
                    fontSize: "12px"
                }}
            >
                {text}
            </span></div>
    );
};

export default Pill;
