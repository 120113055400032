import React, { useEffect, useState } from "react";
import Arrow from "../../../assets/images/icons/icn_arrow_right_blue.png";
import Focus from "../../../components/ui/Focus";
import Workout from "../../../components/ui/Workout";
import Athlete from "../../../components/ui/Athlete";
import axios from "../../../helper/api/api_instance";
import toastr from "toastr";
import Spinner from "../../../components/ui/spinner";
import FormModal from "../../../components/modals/FormModal";
import AssignWorkoutModal from "../../../components/modals/AssignWorkoutModal";
import { useNavigate } from "react-router-dom";
import { Base_Route, focusColors } from "../../../helper/constant";
import Slider from "../../../components/ui/Slider";

const Dashboard = () => {
  const [focus, setFocus] = useState([]);
  const [workout, setWorkout] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [athlete, setAthlete] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [focusLoading, setFocusLoading] = useState(false);
  const [workoutLoading, setWorkoutLoading] = useState(false);
  const [athleteLoading, setAthleteLoading] = useState(true);
  const [workoutId, setWorkoutId] = useState("");
  const navigate = useNavigate();
  let count = -1;

  useEffect(() => {
    getAllFocuses();
    getWorkout();
    getCoachAthlete();
  }, []);

  const getAllFocuses = () => {
    setFocusLoading(true);
    axios
      .post(`exercise_type_list`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setFocus(res?.data?.data);
          setFocusLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const getWorkout = () => {
    setWorkoutLoading(true);
    const fd = new FormData();
    fd.append(
      "login_user_id",
      JSON.parse(localStorage.getItem("AuthUser")).UserId
    );
    axios
      .post(`find_workout_list`, fd)
      .then((res) => {
        setWorkoutLoading(false);
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setWorkout(res?.data?.data);
        }
      })
      .catch((error) => {
        setWorkoutLoading(false);
        toastr.error(error.message);
      });
  };

  const getCoachAthlete = () => {
    axios
      .post(`coach_athlete_list`)
      .then((res) => {
        setAthleteLoading(false);
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setAthlete(res?.data?.data);
        }
      })
      .catch((error) => {
        setAthleteLoading(false);
        toastr.error(error.message);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const assignWorkoutHandler = (val, id) => {
    setWorkoutId(id);
    setLoading(true);
    const fd = new FormData();
    fd.append("exercise_id", val);
    axios
      .post(`exercise_adjustment_list`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setExercises(res?.data?.data);
          setVisible(true);
          setLoading(false);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const openAthleteProfile = (id) => {
    navigate(`${Base_Route}/trufit/athlete-profile`, { state: { id } });
  };

  const selectedFocusHandler = (id, title) => {
    navigate(`${Base_Route}/trufit/exercises`, {
      state: { focus_id: id, focus_title: title },
    });
  };

  return (
    <>
      <div className="">
        <div className="activity-scroll-d">
          <div style={{ paddingTop: "25px", marginLeft: "25px" }}>
            <span className="coach-sm-heading">Welcome Coach</span>
            <p className="coach-name-heading">
              {
                JSON.parse(localStorage.getItem("UserProfile")).UserProfile
                  .user_name?.toUpperCase()
              }
            </p>
          </div>
          <div style={{ width: "95%", marginInlineStart: 20 }}>
            {focusLoading ? (
              <div
                style={{
                  marginTop: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <Spinner color="#d9d9d9" />
              </div>
            ) : (
              <Slider>
                {focus.map((x, i) => {
                  if (count === focusColors.length - 1) {
                    count = 0;
                  } else {
                    count++;
                  }
                  return (
                    <Focus
                      key={i}
                      title={x.fouce_title}
                      id={x.fouce_id}
                      image={x.focus_image}
                      color={focusColors[count]}
                      handleSelectFocus={selectedFocusHandler}
                    />
                  );
                })}
              </Slider>
            )}
          </div>

          <div className="exercise-athlete-div mt-4">
            <div
              className="workout-section"
              style={{
                padding: "25px",
                marginRight: "20px",
                marginBottom: "20px",
              }}
            >
              <div className=" d-flex align-items-center justify-content-between">
                <p className="workout-heading">Workout</p>
                <p
                  className="view-all"
                  onClick={() => navigate(`${Base_Route}/trufit/workout`)}
                >
                  View All{" "}
                  <img
                    src={Arrow}
                    alt="blue-arrow"
                    style={{ height: "14px", marginBottom: "2.5px" }}
                  />{" "}
                </p>
              </div>
              <div className={workoutLoading ? "spinner-workout" : ""}>
                {workoutLoading ? (
                  <Spinner color="#d9d9d9" />
                ) : workout.length > 0 ? (
                  <>
                    {workout.map((x, i) => {
                      return (
                        <Workout
                          key={i}
                          id={x.workout_id}
                          title={x.workout_title}
                          description={x.long_desc}
                          onClickButton={assignWorkoutHandler}
                          code={x.exercise_id}
                          loading={loading}
                          selectedWorkout={workoutId}
                          fullWorkout={x}
                          workout={workout}
                          setWorkout={setWorkout}
                        />
                      );
                    })}
                  </>
                ) : (
                  <span className="d-flex align-items-center justify-content-center view-more pt-3 pb-1">
                    No workouts yet
                  </span>
                )}
              </div>
            </div>
            <div
              className="athletes-section"
              style={{
                padding: "25px",
                marginRight: "0px",
                marginBottom: "20px",
              }}
            >
              <div className="d-flex align-items-center justify-content-between">
                <span className="workout-heading">My Athletes</span>
              </div>
              <div className={workoutLoading ? "spinner-workout" : ""}>
                {athleteLoading ? (
                  <Spinner color="#d9d9d9" />
                ) : athlete.length > 0 ? (
                  <>
                    {athlete.map((x, i) => {
                      return (
                        <Athlete
                          key={i}
                          profile={x.other_user_profile_pic}
                          name={x.other_user_name}
                          id={x.other_user_id}
                          openDetailHandler={openAthleteProfile}
                        />
                      );
                    })}
                  </>
                ) : (
                  <span className="d-flex align-items-center justify-content-center view-more pt-3 pb-1">
                    No athletes yet
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormModal
        visible={visible}
        onCancel={handleCancel}
        content={
          <AssignWorkoutModal
            workoutId={workoutId}
            handleCancel={handleCancel}
            exercises={exercises}
            setVisible={setVisible}
          />
        }
      />
    </>
  );
};

export default Dashboard;
