import React, { useEffect, useState } from "react";
import AthletePic from "../../assets/images/core/Athlete.png";
import DisablePeople from "../../assets/images/icons/icn_disability.png";
import Calendar from "../../assets/images/icons/icn_calendar.png";
import toastr from "toastr";
import axios from "../../helper/api/api_instance";
import { DatePicker, Radio } from "antd";
import { useLocation } from "react-router-dom";
import ProfileWorkout from "../../components/ui/ProfileWorkout";
import AssignWorkoutDetail from "../../components/ui/AssignWorkoutDetail";
import Exercise from "../../components/ui/Exercise";
import Spinner from "../../components/ui/spinner";
import FormModal from "../../components/modals/FormModal";
import Disabilities from "../../components/ui/disabilities";
import ButtonData from "../../components/ui/button";
import { FavoritesOptions, WorkoutOptions } from "../../helper/constant";

const AthleteProfile = () => {
  const location = useLocation();
  const [workout, setWorkout] = useState("Completed_Workouts");
  const [favourite, setFavourite] = useState("workout");
  const [modal, setModal] = useState(false);
  const [date, setDate] = useState("");
  const [dateWise, setDateWise] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [athleteData, setAthleteData] = useState({});
  const [workoutState, setWorkoutState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDateWise, setLoadingDateWise] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);
  const [workoutDetail, setWorkoutDetail] = useState({});
  const [visible, setVisible] = useState(false);
  const { id, selected } = location.state;

  const getExerciseDateWise = (val) => {
    setLoadingDateWise(true);
    setDateWise(true);
    setFilterData([]);
    const fd = new FormData();
    fd.append("search_date", val);
    fd.append("user_id", id);
    axios
      .post(`date_wise_exercise`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setFilterData(res?.data?.data);
          setLoadingDateWise(false);
        } else {
          toastr.error(res.data.message);
          setLoadingDateWise(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setLoadingDateWise(false);
      });
  };
  const getAthleteData = (id) => {
    setLoading(true);
    const fd = new FormData();
    fd.append("user_id", id);
    axios
      .post(`my_workout_list`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setAthleteData(res?.data?.data);
          setLoading(false);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const getWorkoutDetail = (val, assign_id) => {
    setDetailLoading(true);
    const fd = new FormData();
    fd.append("workout_id", val);
    fd.append("assign_workout_user_id", assign_id);
    axios
      .post(`workout_details`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setWorkoutDetail(res?.data?.data);
          setDetailLoading(false);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  useEffect(() => {
    getAthleteData(id);

    if (location.state.selected !== null) {
      localStorage.setItem(
        "Focus",
        JSON.stringify({
          group_id: selected,
        })
      );
    }
  }, [id]);

  const onChange4 = ({ target: { value } }) => {
    setWorkout(value);
    setDateWise(false);
    setWorkoutState(false);
  };

  const onChangeFav = ({ target: { value } }) => {
    setFavourite(value);
    setWorkoutState(false);
  };

  const onChange = (date, dateString) => {
    setDate(dateString);
  };

  const handleApplyDateFilter = () => {
    setModal(!modal);
    getExerciseDateWise(date);
  };

  const viewDetailHandler = (workout_id, assign_id) => {
    getWorkoutDetail(workout_id, assign_id);
    setWorkoutState(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "80vh" }}
        >
          <Spinner color="#d9d9d9" />
        </div>
      ) : (
        <>
          <div className="m-4">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <img
                  src={
                    athleteData?.profile_pic
                      ? athleteData?.profile_pic
                      : AthletePic
                  }
                  alt="athlete-pic"
                  style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                />
                <span className="mx-2 athlete-profile-name">
                  {athleteData?.user_name}
                </span>
              </div>
              <div
                onClick={() => setVisible(true)}
                className="d-flex align-items-center justify-content-center blue-circle-bg"
              >
                <img
                  src={DisablePeople}
                  alt="disable-people"
                  style={{ height: "20px" }}
                />
              </div>
            </div>
            <p className="athlete-profile-name mt-4">My Workouts</p>
            <div className="row mt-4">
              <div className="col-sm-6 col-lg-6 col-xl-4 athlete-radio-group">
                <Radio.Group
                  options={WorkoutOptions}
                  onChange={onChange4}
                  value={workout}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
              <div className="col-xl-8 d-flex justify-content-end">
                {workout === "Completed_Workouts" && (
                  <>
                    <DatePicker
                      open={modal}
                      onChange={onChange}
                      showToday={false}
                      shouldCloseOnSelect={true}
                      renderExtraFooter={() => (
                        <div className="d-flex justify-content-end p-2">
                          <div className="d-flex justify-content-between">
                            <ButtonData
                              type="border-blue-btn"
                              onClick={() => setModal(!modal)}
                            >
                              Cancel
                            </ButtonData>
                            <ButtonData
                              type="filled-blue-btn mx-2"
                              onClick={() => handleApplyDateFilter()}
                            >
                              Ok
                            </ButtonData>
                          </div>
                        </div>
                      )}
                    />
                    <div
                      onClick={() => setModal(!modal)}
                      className="d-flex align-items-center justify-content-center blue-circle-bg"
                    >
                      <img
                        src={Calendar}
                        alt="disable-people"
                        style={{ height: "20px" }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            {workout === "Favourites" && (
              <>
                <div className="row mt-4">
                  <div className="col-sm-6 col-lg-6 col-xl-4 favourite-radio-group mt-4">
                    <Radio.Group
                      options={FavoritesOptions}
                      onChange={onChangeFav}
                      value={favourite}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </div>
                </div>
              </>
            )}
            {(workout === "Assigned_Workouts" ||
              workout === "Completed_Workouts" ||
              (workout === "Favourites" && favourite === "workout")) &&
            workoutState === true ? (
              <AssignWorkoutDetail
                workout={workout}
                WorkoutLoading={detailLoading}
                detail={workoutDetail}
                setWorkoutState={setWorkoutState}
              />
            ) : workout === "Completed_Workouts" &&
              dateWise === true &&
              filterData.length === 0 ? (
              <div
                style={{ height: "50vh" }}
                className="d-flex justify-content-center align-items-center"
              >
                {loadingDateWise ? (
                  <Spinner color="#d9d9d9" />
                ) : (
                  <p className="exercise-heading">No Workout Found!</p>
                )}
              </div>
            ) : (
              <div className="row">
                {(workout === "Completed_Workouts" && dateWise === false
                  ? athleteData?.completed_workout_data
                  : workout === "Completed_Workouts" && dateWise === true
                  ? filterData
                  : workout === "Assigned_Workouts"
                  ? athleteData?.assign_workout_data
                  : workout === "Favourites" && favourite === "workout"
                  ? athleteData?.favourite_data?.get_fav_workout_data
                  : workout === "Favourites" && favourite === "exercises"
                  ? athleteData?.favourite_data?.get_fav_exercise_data
                  : undefined
                )?.map((x, i, arrayRef) => {
                  if (workout === "Favourites" && favourite === "exercises") {
                    return (
                      <div key={i} className="col-lg-6">
                        <Exercise
                          id={x?.exercise_id}
                          image={x?.exercise_images[0]?.exercise_image}
                          name={x?.exercise_name}
                          description={x?.sort_description}
                          pageName="athlete-profile"
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={i} className="col-lg-4">
                        <ProfileWorkout
                          title={x.workout_title}
                          description={x.long_desc}
                          id={x.workout_id}
                          date={
                            workout === "Completed_Workouts"
                              ? x.log_workout_date
                                ? x.log_workout_date
                                : date
                              : workout === "Assigned_Workouts"
                              ? x.created_at
                              : undefined
                          }
                          assignId={x?.assign_workout_user_id}
                          workout={workout}
                          arrow={
                            workout === "Completed_Workouts" ? false : true
                          }
                          viewDetail={viewDetailHandler}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </div>
        </>
      )}

      <FormModal
        title="Disabilities"
        visible={visible}
        onCancel={handleCancel}
        content={
          <Disabilities cancel={handleCancel} visible={visible} id={id} />
        }
      />
    </>
  );
};

export default AthleteProfile;
