import React from "react";
import exerciseImg from "../../assets/images/core/Feed-img.png";
import BackArrow from "../../assets/images/icons/icn_back.png";
import ButtonData from "./button";
import { Typography } from "antd";
import Spinner from "./spinner";
import { Base_Route } from "../../helper/constant";
import { useNavigate } from "react-router-dom";
import FormModal from "../modals/FormModal";
import { useState } from "react";
import AssignWorkoutModal from "../modals/AssignWorkoutModal";
import toastr from "toastr";
import axios from "../../helper/api/api_instance";
import LogWorkoutModal from "../modals/LogWorkoutModal";
import StripeModal from "../modals/StripeModal";
import HappyYellowEmoji from "../../assets/images/icons/icn_happy_slctd.png";
import UnHappyYellowEmoji from "../../assets/images/icons/icn_unhappy_slctd.png";
import SadYellowEmoji from "../../assets/images/icons/icn_sad_slctd.png";
import Like from "../../assets/images/icons/icn_like_slctd.png";
import LikeGrey from "../../assets/images/icons/icn_like_unslctd.png";
const { Text } = Typography;
const AssignWorkoutDetail = ({
  workout,
  WorkoutLoading,
  detail,
  setWorkoutState,
  getAthleteData,
  refreshCall,
}) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [exercises, setExercises] = useState([]);
  const [loading, setLoading] = useState(false);
  const user_type = JSON.parse(localStorage.getItem("UserProfile"))?.UserProfile
    ?.user_type;

  const assignWorkoutHandler = (val) => {
    setLoading(true);
    const fd = new FormData();
    fd.append("exercise_id", val);
    axios
      .post(`exercise_adjustment_list`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setExercises(res?.data?.data);
          setVisible(true);
          setLoading(false);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };
  return (
    <>
      <div className="mt-4">
        {WorkoutLoading ? (
          <div
            className="mt-5 d-flex justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <Spinner color="#d9d9d9" />
          </div>
        ) : (
          <>
            <div className="back-arrow" onClick={() => setWorkoutState(false)}>
              <img
                src={BackArrow}
                alt="black-arrow"
                style={{ height: "12px" }}
              />
              <p className="no-accout mx-2 mt-3">Back</p>
            </div>
            <p className="workout-heading-detail">{detail?.workout_title}</p>
            {detail?.workout_notes !== "" && (
              <>
                <p className="notes">Notes</p>
                <p className="notes-detail">{detail?.workout_notes}</p>
              </>
            )}
            <div className="row gx-3 gy-3 ">
              {detail?.get_exercise_data?.map((x, i) => {
                return (
                  <div
                    key={i}
                    style={{ cursor: "pointer" }}
                    className="col-lg-6 col-md-6 col-xl-3 cursor-pointer"
                    onClick={() =>
                      navigate(`${Base_Route}/trufit/exercise-detail`, {
                        state: { id: x?.exercise_id },
                      })
                    }
                  >
                    <div className="workout-exercise-card">
                      <img
                        src={
                          x.exercise_images?.[0]
                            ? x.exercise_images?.[0]?.exercise_image
                            : exerciseImg
                        }
                        style={{
                          height: "55px",
                          width: "80px",
                          borderRadius: "10px",
                        }}
                        alt="exercise"
                      />
                      <div className=" mx-2 d-flex flex-column">
                        <Text
                          className="notes mb-0"
                          style={{
                            width: 200,
                          }}
                          ellipsis={true}
                        >
                          {x?.exercise_name}
                        </Text>
                        {workout !== "Saved_Workouts" && (
                          <>
                            <span className="adjustment-and-sets">
                              Adjustment: {x?.adjustments_name}
                            </span>
                            <span className="adjustment-and-sets">
                              Set: {x?.set_name}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {workout === "Completed_Workouts" && (
              <div className="mt-4">
                <p className="workout-heading-detail">Feedback</p>
                <div>
                  <p className="notes mb-0">How Difficult Was it ?</p>
                  <div className="d-flex justify-content-between w-75 m-2">
                    <img
                      src={
                        detail?.is_difficult === "happy"
                          ? HappyYellowEmoji
                          : detail?.is_difficult === "normal"
                          ? UnHappyYellowEmoji
                          : SadYellowEmoji
                      }
                      alt="emoji"
                      height="30px"
                      // onClick={() => setIsDifficult("happy")}
                    />
                  </div>
                </div>

                <div>
                  <p className="notes mb-0">Did you Enjoy This Activity ?</p>
                  <ButtonData
                    style={{ width: "120px" }}
                    type={
                      detail?.enjoy_activity === "yes"
                        ? "like-btn m-3"
                        : "dislike-btn mt-3 mb-3"
                    }
                  >
                    <img
                      src={detail?.enjoy_activity === "yes" ? Like : LikeGrey}
                      alt="emoji"
                      height="30px"
                      style={{
                        transform:
                          detail?.enjoy_activity === "yes"
                            ? "rotateZ(0deg)"
                            : "rotateZ(180deg)",
                      }}
                    />
                    {detail?.enjoy_activity === "yes" ? "YES" : "NO"}
                  </ButtonData>
                </div>
                {detail?.question_list?.map((x, i) => {
                  return (
                    <>
                      <p className="notes mb-0">{x?.workout_question}</p>
                      <p className="notes-detail">{x?.workout_answer}</p>
                    </>
                  );
                })}
              </div>
            )}
            <ButtonData
              type="mt-4 assign-btn assign-workout-btn"
              onClick={() => assignWorkoutHandler(detail?.exercise_id)}
              loading={loading}
            >
              {user_type === "coach"
                ? "Assign Workout"
                : "Log Completed Workout"}
            </ButtonData>
          </>
        )}
      </div>
      <FormModal
        visible={visible}
        onCancel={() => setVisible(false)}
        content={
          user_type === "coach" ? (
            <AssignWorkoutModal
              workoutId={detail?.workout_id}
              handleCancel={() => setVisible(false)}
              exercises={exercises}
              setVisible={setVisible}
            />
          ) : (
            <LogWorkoutModal
              workout={workout}
              workoutId={detail?.workout_id}
              exercise_ids={detail?.exercise_id}
              defaultExercises={detail?.get_exercise_data}
              handleCancel={() => setVisible(false)}
              exercises={exercises}
              setVisible={setVisible}
              getAthleteData={getAthleteData}
              refreshCall={refreshCall}
            />
          )
        }
      />
    </>
  );
};

export default AssignWorkoutDetail;
