import { Select } from "antd";
import React, { useState } from "react";

const AssignExercise = ({
  id,
  name,
  set,
  adjustment,
  description,
  handleChange,
  defaultValue,
  workout,
  adjustmentData
}) => {

 
  const adjustmentValue = adjustment?.map((x, i) => {
    return { value: x.exercise_adjustment_id, label: x.adjustments_name };
  });

  const repsValue = set?.map((x, i) => {
    return { value: x.exercise_set_id, label: x.set_name };
  });

  const checkDefaultAdjustment = (adjustmentList, name, check) => {
    if(check){
      for (const adjustment of adjustmentList) {
        const adjIndex = adjustmentData.findIndex(({ e_id }) => e_id === id);
        if (adjIndex > -1) {
          adjustmentData[adjIndex][name] = adjustment.exercise_set_id;
        } else {
          adjustmentData.push({ e_id: id, [name]: adjustment.exercise_set_id });
        }
        return adjustment.exercise_set_id;
      }

    }else{
    for (const adjustment of adjustmentList) {
        if (adjustment.is_default) {
          const adjIndex = adjustmentData.findIndex(({ e_id }) => e_id === id);
          if (adjIndex > -1) {
            adjustmentData[adjIndex][name] = adjustment.exercise_adjustment_id;
          } else {
            adjustmentData.push({ e_id: id, [name]: adjustment.exercise_adjustment_id });
          }
            return adjustment.adjustments_name;
        }
    }
    for (const adjustment of adjustmentList) {
        if (adjustment.adjustment_level === "None") {
          const adjIndex = adjustmentData.findIndex(({ e_id }) => e_id === id);
          if (adjIndex > -1) {
            adjustmentData[adjIndex][name] = adjustment.exercise_adjustment_id;
          } else {
            adjustmentData.push({ e_id: id, [name]: adjustment.exercise_adjustment_id });
          }
            return adjustment.adjustments_name;
        }
    }
    return `Select ${check ? 'Sets' : "Adjustments"}`;
}
  }
  return (
    <>
      <div className="select-exercise-item">
        <div className="slct-exrcse-itm-title-container">
          <div className="slct-exrcse-itm-title">{name}</div>
          <div className="slct-exrcse-itm-description">{description}</div>
        </div>

        <Select
          // defaultValue="Select Adjustment"
          defaultValue={
            workout === "Assigned_Workouts"
              ? defaultValue[0]?.adjustments_name
              : checkDefaultAdjustment(adjustment, "adj_id")
          }
          className="select-dropdown"
          onChange={(val) => handleChange(val, id, "adj_id")}
          options={adjustmentValue}
        />
        <Select
          // defaultValue="Select Reps"
          defaultValue={
            workout === "Assigned_Workouts"
              ? defaultValue[0]?.set_name
              : checkDefaultAdjustment(set, "set_id", true)
              // set[0]?.set_name
          }
          className="select-dropdown"
          onChange={(val) => handleChange(val, id, "set_id")}
          options={repsValue}
        />
      </div>
    </>
  );
};

export default AssignExercise;
