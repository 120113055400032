import React from "react";
import ModalCross from "../../assets/images/icons/icn_close.png";
import CopyToClipboard from "react-copy-to-clipboard";
import InviteFriends from "../../assets/images/icons/icn_invite_friends.png";
import Inputfield from "../ui/Inputfield";
import Copy from "../../assets/images/icons/icn_copy.png";
import toastr from "toastr";

const InviteModal = ({ handleCancel }) => {
  const text = "https://gettrufit.com/";

  const goToMyWeb = (e, val) => {
    if (val === true) {
      toastr.success("Link copied to clipboard");
    }
  };
  return (
    <>
      <img
        src={ModalCross}
        alt="modal-cross"
        className="modal-cross"
        onClick={(e) => handleCancel(e)}
      />
      <div className="mt-2" style={{ width: "400px" }}>
        <div className="d-flex mb-4 align-items-center text-decoration-none">
          <img
            src={InviteFriends}
            alt="invite-friend"
            className="invite-friend"
          />
          <span className="mx-2 invite-heading">Invite Friends</span>
        </div>
        <span className="invite-link-des">Please open the below link</span>
        <Inputfield
          onClick={() =>
            window.open(text, "_blank")
          }
          placeholder="Enter Invite Code"
          className="modal-field mt-3 mb-3"
          style={{ caretColor: "transparent", cursor: "pointer" }}
          value={text}
          suffix={
            <CopyToClipboard text={text} onCopy={(e) => goToMyWeb(e, true)}>
              <img
                className="feed-heart-icon"
                alt=""
                src={Copy}
                style={{ height: "20px" }}
              />
            </CopyToClipboard>
          }
        />
      </div>
    </>
  );
};

export default InviteModal;
