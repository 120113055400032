import React, { useState } from "react";
import faqMinus from "../../assets/images/icons/icn_collapse_faqs.png";
import faqPlus from "../../assets/images/icons/icn_expand_faqs.png";

const FaqRequest = ({ faq }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="d-flex justify-content-between  faq-div py-2 mt-3" onClick={() => setOpen(!open)}>
        <p className="faq-sub-heading">{faq?.faq_question}</p>
        <img
          src={open ? faqMinus : faqPlus}
          alt="minus-plus"
          style={{ height: "25px", width: "25px" }}
        />
      </div>
      {open && <p className="mt-2 exercise-title-desc">{faq?.faq_answer}</p>}
    </>
  );
};

export default FaqRequest;
