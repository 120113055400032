import React from "react";
import ButtonData from "../ui/button";

const DeleteAccoutModal = ({
  deleteAccountHandler,
  setDeleteModal,
  deleteModal,
}) => {
  return (
    <>
      <div style={{ width: "250px" }}>
        <p>
          Once deleted, all data associated with your profile will be Completely
          removed from our platform. You will be able to create a new account
          with the same Email if you want to come back!
        </p>
        <div className="d-flex justify-content-end">
          <div className="d-flex justify-content-between">
            <ButtonData
              type="border-btn mx-2"
              onClick={() => setDeleteModal(!deleteModal)}
            >
              Cancel
            </ButtonData>
            <ButtonData
              type="assign-btn"
              onClick={() => deleteAccountHandler()}
            >
              ok
            </ButtonData>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteAccoutModal;
