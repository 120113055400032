import { Radio } from "antd";
import React from "react";
import { useState } from "react";
import { Base_Route, FavoriteScreensOptions } from "../../../helper/constant";
import { useEffect } from "react";
import toastr from "toastr";
import axios from "../../../helper/api/api_instance";
import Exercise from "../../../components/ui/Exercise";
import ProfileWorkout from "../../../components/ui/ProfileWorkout";
import Spinner from "../../../components/ui/spinner";
import { useNavigate } from "react-router-dom";

const Favorites = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState("exercises");
  const [favouriteData, setFavoriteData] = useState({});
  const [loading, setLoading] = useState(false);

  const onChange4 = ({ target: { value } }) => {
    setCurrent(value);
  };

  const getFavoriteData = () => {
    setLoading(true);
    axios
      .post(`athlete_profile`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setFavoriteData(res?.data?.data?.favourite_data);
          setLoading(false);
        } else {
          toastr.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getFavoriteData();
  }, []);

  const onRemoveExercise = (e, id) => {
    e.stopPropagation();
    const fd = new FormData();
    fd.append("exercise_id", id);
    fd.append("is_favourite", "false");
    axios
      .post(`favourite_excrcise`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setFavoriteData({
            ...favouriteData,
            get_fav_exercise_data: favouriteData.get_fav_exercise_data.filter(
              (x) => x.exercise_id !== id
            ),
          });
          toastr.success("Exercise Remove from Favourite");
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setLoading(false);
      });
  };

  const getNavigateWorkoutDetail = (id) => {
    navigate(`${Base_Route}/trufit/workout-detail`, {
      state: { id },
    });
  };

  return (
    <>
      <div className="p-4">
        <p className="athlete-profile-name">Favorites</p>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "80vh" }}
          >
            <Spinner color="#d9d9d9" />
          </div>
        ) : (
          <>
            <div className="col-sm-6 col-lg-6 col-xl-4 favourite-radio-group fav-radio">
              <Radio.Group
                options={FavoriteScreensOptions}
                onChange={onChange4}
                value={current}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
            {current === "workout" ? (
              <div className="row">
                {favouriteData?.get_fav_workout_data?.length > 0 ? (
                  favouriteData?.get_fav_workout_data?.map((x, i) => {
                    return (
                      <div key={i} className="col-lg-6 col-xl-4">
                        <ProfileWorkout
                          title={x.workout_title}
                          description={x.long_desc}
                          id={x.workout_id}
                          getWorkouts={getNavigateWorkoutDetail}
                          type={"workout_detail"}
                        />
                      </div>
                    );
                  })
                ) : (
                  <span className="d-flex align-items-center justify-content-center view-more pt-3 pb-1 activity-loader">
                    No Workout in your Favorites
                  </span>
                )}
              </div>
            ) : (
              <div className="row">
                {favouriteData?.get_fav_exercise_data?.length > 0 ? (
                  favouriteData?.get_fav_exercise_data?.map((x, i) => {
                    return (
                      <div key={i} className="col-lg-6">
                        <Exercise
                          id={x?.exercise_id}
                          image={x?.exercise_images[0]?.exercise_image}
                          name={x?.exercise_name}
                          description={x?.sort_description}
                          onFavourite={onRemoveExercise}
                          mode="playlist"
                        />
                      </div>
                    );
                  })
                ) : (
                  <span className="d-flex align-items-center justify-content-center view-more pt-3 pb-1 activity-loader">
                    No Exercise in your Favorites
                  </span>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Favorites;
