import React, { useEffect, useState } from "react";
import { DatePicker } from "antd";
import toastr from "toastr";
import axios from "../../helper/api/api_instance";
import { Tabs } from "antd";
import ButtonData from "../ui/button";
import { Checkbox } from "antd";
import TextArea from "antd/es/input/TextArea";
import ModalCross from "../../assets/images/icons/icn_close.png";
import StickyBox from "react-sticky-box";
import AssignExercise from "../ui/AssignExercise";
import HappyYellowEmoji from "../../assets/images/icons/icn_happy_slctd.png";
import UnHappyYellowEmoji from "../../assets/images/icons/icn_unhappy_slctd.png";
import SadYellowEmoji from "../../assets/images/icons/icn_sad_slctd.png";
import HappyEmoji from "../../assets/images/icons/icn_happy_unslctd.png";
import UnHappyEmoji from "../../assets/images/icons/icn_unhappy_unslctd.png";
import SadEmoji from "../../assets/images/icons/icn_sad_unslctd.png";
import Like from "../../assets/images/icons/icn_like_slctd.png";
import LikeGrey from "../../assets/images/icons/icn_like_unslctd.png";
import blueCalendar from "../../assets/images/icons/icn_calendar.png";
import img_log_workout_success from "../../assets/images/icons/img_log_workout_success.png";
import dayjs from "dayjs";
import moment from "moment/moment";
import Inputfield from "../ui/Inputfield";

const LogWorkoutModal = ({
  workout,
  handleCancel,
  workoutDetail,
  name,
  setWorkoutId,
  setPlaylist,
  exercises,
  workoutId,
  exercise_ids,
  refreshCall,
  defaultExercises,
}) => {
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [adjustmentData, setAdjustmentData] = useState([]);
  const [success, setSuccess] = useState(false);
  const [modal, setModal] = useState(false);
  const [enjoyActivity, setEnjoyActivity] = useState(true);
  const [workoutNote, setWorkoutNote] = useState("");
  const [achievementName, setAchievementName] = useState("");
  const [tab, setTab] = useState("1");
  const [feed, setFeed] = useState("");
  const [isDifficult, setIsDifficult] = useState("happy");
  const [questionArr, setQuestionArr] = useState([]);
  const [questionData, setQuestionData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    dayjs(new Date()).format("YYYY-MM-DD h:mm:ss")
  );
  const [valArr, setValArr] = useState([]);
  const actionButtonText = "Next";

  const AddExercises = () => {
    defaultExercises?.map((x) => {
      adjustmentData?.push({
        adj_id: x?.exercise_adjustment_id,
        e_id: x?.exercise_id,
        set_id: x?.exercise_set_id,
      });

      valArr?.push(
        { id: x?.exercise_id, name: "set_id", value: x?.exercise_set_id },
        { id: x?.exercise_id, name: "adj_id", value: x?.exercise_adjustment_id }
      );
    });
  };
  useEffect(() => {
    const getAskedQuestions = () => {
      const fd = new FormData();
      fd.append("workout_id", workoutId);
      axios
        .post(`workout_question_list`, fd)
        .then((res) => {
          if (res?.status === 200 && res?.data?.responsecode === 1) {
            setQuestionArr(res?.data?.data);
          } else {
            toastr.error(res.data.message);
          }
        })
        .catch((error) => {
          toastr.error(error.message);
          setLoading(false);
        });
    };
    if (workout === "Assigned_Workouts") {
      getAskedQuestions();
      AddExercises();
    }
  }, []);

  const handleChangeInput = (value, i, id) => {
    const checkVal = questionData?.some((item) => item.workout_note_id === id);
    if (checkVal) {
      const indexofObj = questionData?.findIndex(
        (item) => item?.workout_note_id === id
      );
      if (value !== "") {
        questionData[indexofObj].workout_answer = value;
      } else {
        setQuestionData(
          questionData?.filter((item) => item.workout_note_id !== id)
        );
      }
    } else {
      setQuestionData([
        ...questionData,
        { workout_note_id: id, workout_answer: value },
      ]);
    }
  };

  const handleChange = (value, id, name) => {
    // const checkArr = valArr?.some(
    //   (item) => item.id === id && item.name === name
    // );
    // console.log('check arr===>>>', checkArr);
    // if (checkArr) {
    //   const indexofObj = valArr?.findIndex(
    //     (item) => item.id === id && item.name === name
    //   );
    //   valArr[indexofObj].value = value;
    // } else {
    //   setValArr([...valArr, { id, name, value }]);
    // }
    // const adjustments = [...adjustmentData];
    const adjIndex = adjustmentData.findIndex(({ e_id }) => e_id === id);
    if (adjIndex > -1) {
      adjustmentData[adjIndex][name] = value;
    } else {
      adjustmentData.push({ e_id: id, [name]: value });
    }
    // setAdjustmentData([...adjustments]);
  };

  const onFeedCheckChange = (e) => {
    if (e.target.checked) {
      setFeed("add_feed");
    } else {
      setFeed("");
    }
  };

  const shouldButtonBeDisabled = () => {
    // if (tab == 1) {
    //   if (valArr.length === exercises.length * 2) return false;
    // } else if (tab == 2) {
    //   if (
    //     adjustmentData.length === exercises.length &&
    //     isDifficult !== "" &&
    //     workoutNote !== "" &&
    //     questionData.length === questionArr.length
    //   )
    //     return false;
    // } else if (tab == 3) {
    //   if (
    //     adjustmentData.length === exercises.length &&
    //     workoutNote.length > 0 &&
    //     questionData.length > 0
    //   ) {
    //     return false;
    //   }
    // }
    return false;
  };

  const onChange = (date, dateString) => {
    setSelectedDate(dayjs(date).format("YYYY-MM-DD h:mm:ss"));
  };

  const items = [
    {
      key: "1",
      label: `Exercises`,
      children: (
        <div className="exercises-cntnr">
          {exercises?.map(
            (
              {
                adjustment_list,
                exercise_id,
                exercise_name,
                set_list,
                sort_description,
              },
              i
            ) => {
              return (
                <AssignExercise
                  key={i}
                  id={exercise_id}
                  name={exercise_name}
                  set={set_list}
                  workout={workout}
                  defaultValue={defaultExercises?.filter(
                    (x) => x?.exercise_id === exercise_id
                  )}
                  adjustment={adjustment_list}
                  description={sort_description}
                  handleChange={handleChange}
                  adjustmentData={adjustmentData}
                />
              );
            }
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: `Experience`,
      children: (
        <div className="exp-center">
          <p className="how-difficult">How Difficult Was it ?</p>
          <div className="d-flex justify-content-between w-75">
            <img
              src={isDifficult === "happy" ? HappyYellowEmoji : HappyEmoji}
              alt="emoji"
              height="30px"
              onClick={() => setIsDifficult("happy")}
            />
            <img
              src={isDifficult === "normal" ? UnHappyYellowEmoji : UnHappyEmoji}
              alt="emoji"
              height="30px"
              onClick={() => setIsDifficult("normal")}
            />
            <img
              src={isDifficult === "sad" ? SadYellowEmoji : SadEmoji}
              alt="emoji"
              height="30px"
              onClick={() => setIsDifficult("sad")}
            />
          </div>
          <p className="how-difficult mt-4">Did you Enjoy This Activity ?</p>
          <div
            className="d-flex justify-content-between"
            style={{ width: "60%" }}
          >
            <ButtonData
              type={enjoyActivity === true ? "like-btn" : "dislike-btn"}
              onClick={() => setEnjoyActivity(true)}
            >
              <img
                src={enjoyActivity === true ? Like : LikeGrey}
                alt="emoji"
                height="30px"
              />
              YES
            </ButtonData>
            <ButtonData
              type={enjoyActivity === false ? "like-btn" : "dislike-btn"}
              onClick={() => setEnjoyActivity(false)}
            >
              <img
                src={enjoyActivity === true ? LikeGrey : Like}
                alt="emoji"
                height="30px"
                style={{ transform: "rotateZ(180deg)" }}
              />
              NO
            </ButtonData>
          </div>
          <div className="mt-4">
            {questionArr?.map((x, i) => {
              return (
                <div className="add-notes-input" key={i}>
                  <p className="how-difficult ">{x.workout_question}</p>
                  <Inputfield
                    style={{ height: "50px" }}
                    onChange={(e) =>
                      handleChangeInput(
                        e.target.value,
                        i,
                        x?.assign_workout_note_id
                      )
                    }
                  />
                </div>
              );
            })}
          </div>

          <div className="add-notes-input">
            <p className="how-difficult mt-2">Notes</p>
            <TextArea
              onChange={(e) => setWorkoutNote(e.target.value)}
              placeholder="Notes"
              autoSize={{ minRows: 5, maxRows: 5 }}
            />

            <Checkbox
              onChange={onFeedCheckChange}
              className="no-accout mt-2 my-2 add-to-feed"
            >
              Add to Feed
            </Checkbox>
          </div>
        </div>
      ),
    },
  ];

  const postWorkout = (w_val) => {
    const fd = new FormData();
    fd.append("log_workout_date", selectedDate);
    fd.append("adjustment_data", JSON.stringify(adjustmentData));
    fd.append("notes", workoutNote);
    fd.append("is_feed", feed);
    fd.append("exercise_id", exercise_ids);
    fd.append("is_difficult", isDifficult);
    fd.append("workout_id", w_val ? w_val : workoutId);
    fd.append("question_data", JSON.stringify(questionData));
    fd.append("enjoy_activity", enjoyActivity ? "yes" : "no");
    setLoading(true);
    axios
      .post(`log_workout`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          name === "Log" && setPlaylist([]);
          setAchievementName(res?.data?.achievement_name);
          if (res?.data?.statuscode !== 0) {
            setSuccess(true);
          } else {
            toastr.success("Logged Workout Successfully");
            handleCancel();
          }
          if (workout !== undefined) {
          refreshCall &&  refreshCall();
          }
          setLoading(false);
        } else {
          toastr.error(res.data.message);
          setLoading(false);
          setDisable(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setLoading(false);
      });
  };

  const onTabChange = (key) => {
    setTab(key);
  };

  const onSubmitData = () => {
    if (tab === "1") {
      onTabChange("2");
    } else {
      if (name === "Log") {
        setLoading(true);
        setDisable(true);
        const fd = new FormData();
        fd.append("workout_title", workoutDetail?.name);
        fd.append("long_desc", workoutDetail?.description);
        fd.append("exercise_id", exercise_ids);
        fd.append("workout_status", "is_playlist");
        axios
          .post("create_workout", fd)
          .then(async (res) => {
            if (res?.status === 200 && res?.data?.responsecode === 1) {
              // setPlaylist([]);
              // setWorkoutId(res?.data?.data?.workout_id);
              postWorkout(res?.data?.data?.workout_id);
            } else {
              toastr.error(res.data.message);
            }
          })
          .catch((error) => {
            toastr.error(error.message);
            setDisable(false);
          });
      } else {
        postWorkout();
      }
    }
  };

  const renderTabBar = (props, DefaultTabBar) => (
    <StickyBox
      offsetTop={0}
      offsetBottom={20}
      style={{
        zIndex: 1,
      }}
    >
      <DefaultTabBar
        {...props}
        style={{
          background: "#FFFFFF",
          fontFamily: "poppins-semibold",
        }}
      />
    </StickyBox>
  );

  return (
    <>
      <img
        src={ModalCross}
        alt="modal-cross"
        className="modal-cross"
        onClick={() => handleCancel()}
      />
      <div className="assign-workout-modal ">
        {success ? (
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <p className="workout-head">You Earned A Rising Star Badge</p>
            <p className="congratulation">Congratulations !</p>
            <img
              src={img_log_workout_success}
              className="mt-2"
              alt="congratulation"
              style={{ width: "60%", height: "60%" }}
            />
            <p className="notes mt-2">{achievementName}</p>
            <p className="exercise-desc text-center w-75">
              Workout is good for the body so keep doing workout and earn
              Badges.
            </p>
          </div>
        ) : (
          <>
            <p className="modal-title">Log Completed Workout</p>

            <div className="modal-content">
              <Tabs
                className="tab-container"
                defaultActiveKey={"1"}
                activeKey={tab}
                items={items}
                onChange={onTabChange}
                renderTabBar={renderTabBar}
                tabBarStyle={{ fontFamily: "poppins-semibold" }}
              />
            </div>

            <div className="modal-footer">
              <ButtonData
                type="authButton mt-3"
                loading={loading}
                disabled={shouldButtonBeDisabled() || disable}
                onClick={() => onSubmitData()}
              >
                {tab === "1"
                  ? shouldButtonBeDisabled()
                    ? "Select Adjustments & Reps"
                    : actionButtonText
                  : tab === "2"
                  ? shouldButtonBeDisabled()
                    ? "Select Experience"
                    : `Log Workout For ${dayjs(selectedDate)?.format(
                        "DD MMM YYYY"
                      )}`
                  : null}
              </ButtonData>
            </div>
            {tab === "2" && (
              <>
                <DatePicker
                  open={modal}
                  onChange={onChange}
                  showToday={false}
                  shouldCloseOnSelect={true}
                  disabledDate={(current) => {
                    return current && current > moment().add(0, "month");
                  }}
                  renderExtraFooter={() => (
                    <div className="d-flex justify-content-end p-2">
                      <div className="d-flex justify-content-between">
                        <ButtonData
                          type="filled-blue-btn mx-2"
                          onClick={() => setModal(!modal)}
                        >
                          Ok
                        </ButtonData>
                      </div>
                    </div>
                  )}
                />
                <div
                  className="d-flex justify-content-center mt-3"
                  onClick={() => setModal(true)}
                >
                  <img
                    alt="blue-calender-icon"
                    src={blueCalendar}
                    height="20px"
                  />
                  <p className="change-workout-date mx-2">
                    Change Workout Date{" "}
                  </p>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default LogWorkoutModal;
