import React from "react";
import AthletePic from "../../assets/images/core/Athlete.png";

const Athlete = ({ profile, name, classname, id, type, openDetailHandler }) => {
  return (
    <div
      className={`athlete-profile-div mt-3 ${classname}`}
      onClick={() => openDetailHandler(id, type, profile, name)}
    >
      <img
        src={profile ? profile : AthletePic}
        alt="athlete-pic"
        style={{ height: "50px", width: "50px", borderRadius: "50%" }}
      />
      <span className="athlete-name mx-2">{name}</span>
    </div>
  );
};

export default Athlete;
