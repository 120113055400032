import React from "react";
import { Input } from "antd";

const Inputfield = ({
  disabled,
  onClick,
  status,
  addonAfter,
  addonBefore,
  defaultValue,
  placeholder,
  name,
  type,
  value,
  onChange,
  prefix,
  suffix,
  className,
  dir,
  onKeyDown,
  style,
}) => {
  return (
    <Input
      onClick={onClick}
      style={style}
      onKeyDown={onKeyDown}
      status={status}
      dir={dir}
      disabled={disabled}
      addonAfter={addonAfter}
      addonBefore={addonBefore}
      defaultValue={defaultValue}
      prefix={prefix}
      suffix={suffix}
      placeholder={placeholder}
      className={addonBefore || addonAfter ? "" : `inputfield ${className}`}
      name={name}
      type={type}
      value={value}
      onChange={onChange}
    />
  );
};

export default Inputfield;
