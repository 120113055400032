import React, { useEffect, useState } from "react";
import ButtonData from "../../../components/ui/button";
import { Base_Route } from "../../../helper/constant";
import axios from "../../../helper/api/api_instance";
import Calendar from "../../../assets/images/icons/icn_calendar.png";
import { useNavigate } from "react-router";
import toastr from "toastr";
import { Select, DatePicker } from "antd";
import Spinner from "../../../components/ui/spinner";
const { RangePicker } = DatePicker;
const GenerateReport = () => {
  const navigate = useNavigate();
  const [reportLoading, setReportLoading] = useState(false);
  const [groupDetail, setGroupDetail] = useState({});
  const [group, setGroup] = useState([]);
  const [generateDetail, setGenerateDetail] = useState({
    group_id: "",
    member_id: "",
    date_from: "",
    date_to: "",
  });
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState("");
  const [userId, setUserId] = useState("");

  // const allPropertiesNotEmpty = Object.keys(generateDetail).every(key => generateDetail[key] !== '');

  const Groups = group.map((x, i) => {
    return {
      value: x?.group_id,
      label: x?.group_name,
    };
  });

  const GroupMembers = groupDetail?.user_list
    ?.filter((user) => user.user_type === "athlete")
    .map((user) => ({
      value: user.user_id,
      label: user.user_name,
    }));

  const onChangeDate = (dates, dateString) => {
    setGenerateDetail({
      ...generateDetail,
      date_from: dateString[0],
      date_to: dateString[1],
    });
  };

  const handleChange = (id) => {
    setGenerateDetail({ ...generateDetail, group_id: id });
    getGroupDetail(id);
  };

  const handleMemberChange = (id) => {
    setGenerateDetail({ ...generateDetail, member_id: id });
  };

  const getGroupDetail = (id) => {
    // setGroupDetailLoading(true);
    const fd = new FormData();
    fd.append("group_id", id);
    axios
      .post(`group_details`, fd)
      .then((res) => {
        // setGroupDetailLoading(false);
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setGroupDetail(res?.data?.data);
        }
      })
      .catch((error) => {
        // setGroupDetailLoading(false);
        toastr.error(error.message);
      });
  };

  useEffect(() => {
    getAllGroups();
  }, []);
  const getAllGroups = () => {
    setReportLoading(true);
    axios
      .post(`user_profile`)
      .then(async (res) => {
        setReportLoading(false);
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setGroup(res?.data?.data?.group_list);
          setUserType(res?.data?.data?.user_type);
          setUserId(res?.data?.data?.user_id);
        }
      })
      .catch((error) => {
        setReportLoading(false);
        toastr.error(error.message);
      });
  };

  const handleGenerateReport = () => {
    if (
      (userType === "coach" &&
        generateDetail?.group_id !== "" &&
        generateDetail?.date_from !== "" &&
        generateDetail?.date_to !== "") ||
      (userType === "athlete" &&
        generateDetail?.date_from !== "" &&
        generateDetail?.date_to !== "")
    ) {
      setLoading(true);
      const fd = new FormData();
      fd.append("group_id", generateDetail?.group_id);
      fd.append("member_id", userType === 'coach' ? generateDetail?.member_id : userId );
      fd.append("date_from", generateDetail?.date_from);
      fd.append("date_to", generateDetail?.date_to);
      axios
        .post(`csv_download`, fd)
        .then((res) => {
          setLoading(false);
          if (res?.status === 200) {
            toastr?.success("CSV Downloaded Successfully");
            const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${userType}-report.csv`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          }
        })
        .catch((error) => {
          setLoading(false);
          toastr.error(error.message);
        });
    } else {
      toastr.error("Please add the required data");
    }
  };
  return (
    <>
      {reportLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <Spinner color="#d9d9d9" />
        </div>
      ) : (
        <>
          <div style={{ marginRight: "20px", paddingTop: "25px" }}>
            <div className="d-flex justify-content-between align-items-center">
              <p className="activity">{userType === "coach" ? 'Coach' : 'Athlete'} Report</p>
            </div>
          </div>
          <div className="d-flex flex-wrap mt-4 mx-2">
            {userType === "coach" && (
              <>
                <div className="input-field col-12 col-md-6 col-lg-6">
                  <p className="notes">Select Group</p>
                  <Select
                    defaultValue="Groups"
                    className="select-dropdown"
                    style={{ width: "96%" }}
                    onChange={(val) => handleChange(val)}
                    options={Groups}
                  />
                </div>
                <div className="input-field col-12 col-md-6 col-lg-6">
                  <p className="notes">Select Member(s)</p>
                  <Select
                    defaultValue="Select Member"
                    className="select-dropdown"
                    style={{ width: "96%" }}
                    onChange={(val) => handleMemberChange(val)}
                    options={GroupMembers}
                  />
                </div>
              </>
            )}

            <div className="input-field col-12 col-md-6 col-lg-6 range-picker">
              <p className="notes">Select Date Range</p>
              <RangePicker
                style={{ width: "96%" }}
                // onChange={onRangeChange}
                className="m-10"
                suffixIcon={<img src={Calendar} style={{ height: "25px" }} />}
                // value={dates || value}
                // disabledDate={disabledDate}
                onCalendarChange={onChangeDate}
                // onOpenChange={onOpenChange}
                changeOnBlur
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-4">
            <ButtonData
              loading={loading}
              style={{ width: "94%", height: "50px" }}
              type="mt-4 mx-2 assign-btn"
              onClick={() => handleGenerateReport()}
              disabled={generateDetail?.date_from === "" && generateDetail?.date_to === ""}
            >
              Generate Report
            </ButtonData>
          </div>
        </>
      )}
    </>
  );
};

export default GenerateReport;
