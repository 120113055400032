import React, { useState } from "react";
import ModalCross from "../../assets/images/icons/icn_close.png";
import Inputfield from "../ui/Inputfield";
import { Checkbox } from "antd";
import ExerciseImage from "../../assets/images/core/Feed-img.png";
import ButtonData from "../ui/button";
import toastr from "toastr";
import axios from "../../helper/api/api_instance";
import FilterModal from "./FilterModal";
import FormModal from "./FormModal";
const ExerciseModalCard = ({
  handleCancel,
  exercises,
  onCheckChange,
  assignExercise,
  getExercises,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [filter, setFilter] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const getFilter = () => {
    setFilterLoading(true);
    axios
      .post(
        `filter_data_list`
      )
      .then(async (res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setFilter(res?.data?.data);
          setVisible(true);
          setFilterLoading(false);
        } else {
          toastr.error(res.data.message);
          setFilterLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setFilterLoading(false);
      });
  };
  return (
    <>
      <img
        src={ModalCross}
        alt="modal-cross"
        className="modal-cross"
        onClick={() => handleCancel()}
      />
      <p className="modal-title">Exercises</p>
      <Inputfield
        placeholder="Type here to search"
        className="login-input mb-3"
        onChange={(e) => setSearchInput(e.target.value)}
      />
      <div className="d-flex justify-content-end">
        <ButtonData
          onClick={() => getFilter()}
          style={{ borderRadius: "32px", marginBottom: "12px" }}
          type="border-yellow-btn"
          loading={filterLoading}
        >
          Filters
        </ButtonData>
      </div>
      <div className="exercises-scroll-div">
        {exercises
          .filter((post) => {
            if (searchInput === "") {
              return post;
            } else if (
              post.exercise_name
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            ) {
              return post;
            }
          })
          ?.map((x, i) => {
            return (
              <div
                key={i}
                className="p-2 mt-4 rounded"
                style={{ backgroundColor: "#EEF8FE" }}
              >
                <div className="d-flex justify-content-between mx-2 mt-2">
                  <p className="exercise-heading mt-2">{x?.exercise_name}</p>
                  <Checkbox
                    className="exercise-checkbox"
                    checked={assignExercise.includes(x?.exercise_id)}
                    onChange={(e) => onCheckChange(e, x.exercise_id, x)}
                  />
                </div>
                <img
                  src={
                    x?.exercise_images
                      ? x?.exercise_images[0]?.exercise_image
                      : ExerciseImage
                  }
                  alt="exercise"
                  className="rounded m-2"
                  style={{
                    width: "370px",
                    height: "200px",
                    objectFit: "cover",
                  }}
                />
                <p className="exercise-desc-2 mx-2" style={{ width: "370px" }}>
                  {x?.sort_description}
                </p>
              </div>
            );
          })}
      </div>
      <FormModal
        visible={visible}
        onCancel={() => setVisible(false)}
        content={
          <FilterModal
            handleCancel={() => setVisible(false)}
            filter={filter}
            setVisible={setVisible}
            getExercises={getExercises}
          />
        }
      />
    </>
  );
};

export default ExerciseModalCard;
