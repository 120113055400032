import { Radio } from "antd";
import React, { useEffect, useState } from "react";
import Focus from "../../../components/ui/Focus";
import axios from "../../../helper/api/api_instance";
import toastr from "toastr";
import FormModal from "../../../components/modals/FormModal";
import Spinner from "../../../components/ui/spinner";
import ButtonData from "../../../components/ui/button";
import Exercise from "../../../components/ui/Exercise";
import { useLocation } from "react-router-dom";
import FilterModal from "../../../components/modals/FilterModal";
import { favOptions, focusColors, focusBorder } from "../../../helper/constant";
import Slider from "../../../components/ui/Slider";
import Inputfield from "../../../components/ui/Inputfield";
import Search from "../../../assets/images/icons/icn_search.png";

const Exercises = () => {
  const [focus, setFocus] = useState([]);
  const [filter, setFilter] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [selected, setSelected] = useState();
  const [selectedFocus, setSelectedFocus] = useState();
  const [selectedPlaylist, setSelectedPlaylist] = useState();
  const [loading, setLoading] = useState({});
  const [filterLoading, setFilterLoading] = useState(false);
  const [playlistLoading, setPlaylistLoading] = useState(false);
  const [focusLoading, setFocusLoading] = useState(false);
  const [exerciseLoading, setExerciseLoading] = useState(false);
  const [favourite, setFavourite] = useState("exercises");
  const [visible, setVisible] = useState(false);
  const [focusEnable, setFocusEnable] = useState(false);
  const [search, setSearch] = useState("");
  const location = useLocation();
  let count = -1;
  const checkFocus = localStorage.getItem("Exercise");
  const getAllFocuses = () => {
    setFocusLoading(true);
    axios
      .post(`exercise_type_list`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          if (location.state === null && checkFocus === null) {
            setSelected(res?.data?.data[0]?.fouce_id);
            setSelectedFocus(res?.data?.data[0]?.fouce_title);
            getExercises(res?.data?.data[0]?.fouce_id);
          } else if (checkFocus !== null) {
            setSelected(JSON.parse(checkFocus)?.focus_id);
            setSelectedFocus(JSON.parse(checkFocus)?.fouce_title);
            getExercises(JSON.parse(checkFocus)?.focus_id);
            localStorage?.removeItem("Exercise");
          } else {
            setSelected(location?.state?.focus_id);
            setSelectedFocus(location?.state?.focus_title);
            getExercises(location?.state?.focus_id);
          }
          setFocus(res?.data?.data);
          setFocusLoading(false);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const getExercises = (id, name) => {
    setFocusEnable(true);
    setExerciseLoading(true);
    const fd = new FormData();
    if (name === "by_filter") {
      fd.append("filter_disability_id", id?.ability);
      fd.append("filter_focus_id", id?.focus);
      fd.append("filter_equipment_id", id?.equipment);
      fd.append("exercise_type_id", selected);
    } else {
      fd.append("filter_focus_id", id);
    }
    axios
      .post(`ability_wise_exercise_list`, fd)
      .then(async (res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setFocusEnable(false);
          setExercises(res?.data?.data);
          setExerciseLoading(false);
        } else {
          toastr.error(res.data.message);
          setFocusEnable(false);
          setExerciseLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setFocusEnable(false);
        setExerciseLoading(false);
      });
  };

  const getSessionExercises = () => {
    setExerciseLoading(true);
    axios
      .post(`session_exercise_list`)
      .then(async (res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setExercises(res?.data?.data);
          setExerciseLoading(false);
        } else {
          toastr.error(res.data.message);
          setExerciseLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setExerciseLoading(false);
      });
  };

  const onChangeFav = ({ target: { value } }) => {
    setFavourite(value);
    if (value === "session") {
      getSessionExercises();
    } else {
      getExercises(selected);
    }
  };

  useEffect(() => {
    getAllFocuses();
  }, []);

  const selectedFocusHandler = (id, title) => {
    setSelected(id);
    setSelectedFocus(title);
    getExercises(id);
  };

  const onFavouriteHandler = async (e, id, status) => {
    e.stopPropagation();
    const duplicateExercises = [...exercises];
    const index = await duplicateExercises.findIndex(
      ({ exercise_id }) => exercise_id === id
    );
    setLoading(id);

    const fd = new FormData();
    fd.append("exercise_id", id);
    fd.append("is_favourite", status === "true" ? "false" : "true");
    axios
      .post(`favourite_excrcise`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          duplicateExercises[index].fav_status =
            status === "true" ? "false" : "true";
          setExercises(duplicateExercises);
          setLoading(-1);
        } else {
          toastr.error(res.data.message);
          setLoading(-1);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setLoading(false);
      });
  };

  const addToPlaylistHandler = (e, id) => {
    e.stopPropagation();
    setSelectedPlaylist(id);
    setPlaylistLoading(true);
    const fd = new FormData();
    fd.append("exercise_id", id);
    axios
      .post(`add_execise_to_playlist`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          toastr.success("Exercise added to Playlist");
          setPlaylistLoading(false);
        } else {
          toastr.error(res.data.message);
          setPlaylistLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setPlaylistLoading(false);
      });
  };

  const getFilter = () => {
    setFilterLoading(true);
    axios
      .post(`filter_data_list`)
      .then(async (res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setFilter(res?.data?.data);
          setVisible(true);
          setFilterLoading(false);
        } else {
          toastr.error(res.data.message);
          setFilterLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setFilterLoading(false);
      });
  };

  return (
    <>
      <div className="exercise-main-div">
        <p className="athlete-profile-name">Browse Exercises</p>
        <div className="col-sm-6 col-lg-6 col-xl-4 favourite-radio-group fav-radio mt-4">
          <Radio.Group
            options={favOptions}
            onChange={onChangeFav}
            value={favourite}
            optionType="button"
            buttonStyle="solid"
            className="exercise-radio"
            disabled={
              (exerciseLoading && favourite === "session") || exerciseLoading
            }
          />
        </div>
        <Inputfield
          suffix={<img alt="" src={Search} style={{ height: "15px" }} />}
          placeholder="Search Here"
          className="input-header mt-4"
          onChange={(e) => setSearch(e.target.value)}
        />

        {favourite === "exercises" && (
          <>
            {focusLoading ? (
              <div
                style={{
                  marginTop: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <Spinner color="#d9d9d9" />
              </div>
            ) : (
              <Slider>
                {focus.map((x, i) => {
                  if (count === focusColors.length - 1) {
                    count = 0;
                  } else {
                    count++;
                  }
                  return (
                    <Focus
                      key={i}
                      selected={selected}
                      title={x.fouce_title}
                      id={x.fouce_id}
                      image={x.focus_image}
                      color={focusColors[count]}
                      borderColor={focusBorder[count]}
                      type="exercises"
                      handleSelectFocus={selectedFocusHandler}
                      focus={focusEnable}
                    />
                  );
                })}
              </Slider>
            )}
            <div className="d-flex justify-content-between align-items-center mt-4">
              <p className="athlete-profile-name">{selectedFocus}</p>
              <ButtonData
                onClick={() => getFilter()}
                style={{ borderRadius: "32px" }}
                type="border-yellow-btn"
                loading={filterLoading}
              >
                Filters
              </ButtonData>
            </div>
          </>
        )}

        {exerciseLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "40vh" }}
          >
            <Spinner color="#d9d9d9" />
          </div>
        ) : (
          <div className="row mb-3">
            {exercises
              ?.filter((post) => {
                if (search === "") {
                  return post;
                } else if (
                  post.exercise_name
                    .toLowerCase()
                    .includes(search.toLowerCase())
                ) {
                  return post;
                }
              })
              ?.map((x, i) => {
                return (
                  <div key={i} className="col-lg-6 col-sm-12">
                    <Exercise
                      id={x?.exercise_id}
                      image={x?.exercise_images[0]?.exercise_image}
                      name={x?.exercise_name}
                      description={x?.sort_description}
                      status={x?.fav_status}
                      onFavourite={onFavouriteHandler}
                      loading={loading && x.exercise_id === loading}
                      onAddPlaylist={addToPlaylistHandler}
                      playlistLoading={playlistLoading}
                      selected={selectedPlaylist}
                      mode="exercises"
                      focus_id={selected}
                      focus_title={selectedFocus}
                    />
                  </div>
                );
              })}
          </div>
        )}
      </div>
      <FormModal
        visible={visible}
        onCancel={() => setVisible(false)}
        destroy={"yes"}
        content={
          <FilterModal
            handleCancel={() => setVisible(false)}
            filter={filter}
            setVisible={setVisible}
            getExercises={getExercises}
          />
        }
      />
    </>
  );
};

export default Exercises;
