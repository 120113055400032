import React from "react";
import { Form } from "antd";
import Inputfield from "../ui/Inputfield";
import TextArea from "antd/es/input/TextArea";
import ModalCross from "../../assets/images/icons/icn_close.png";
import ButtonData from "../ui/button";
const CreateWorkoutModal = ({ handleCancel, name, onFinish, loading }) => {
  return (
    <>
      <div style={{ width: "370px" }}>
        <img
          src={ModalCross}
          alt="modal-cross"
          className="modal-cross"
          onClick={() => handleCancel()}
        />
        <p className="modal-title">{`${name} Workout`}</p>
        <Form
          name="modal-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter your workout name",
              },
            ]}
          >
            <Inputfield
              placeholder="Enter Workout Name"
              className="modal-field mt-3"
            />
          </Form.Item>

          <Form.Item
            name="description"
            rules={[
              {
                required: true,
                message: "Please enter your description",
              },
            ]}
          >
            <TextArea
              className="mt-3 modal-field"
              rows={6}
              placeholder="Description"
              maxLength={300}
            />
          </Form.Item>
          <Form.Item>
            <ButtonData
              loading={loading}
              htmltype="submit"
              type="modal-btn assign-btn mt-3"
            >
              {name === "Save" ? "Save Workout" : "Next"}
            </ButtonData>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default CreateWorkoutModal;
