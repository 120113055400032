import React, { useEffect, useState } from "react";
import toastr from "toastr";
import { Form } from "antd";
import { Radio } from "antd";
import { Checkbox } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthFrame from "../../../components/ui/AuthFrame";
import Logo from "../../../assets/images/icons/img_logo_full.png";
import UserLogo from "../../../assets/images/icons/icn_profile.png";
import ReferalLogo from "../../../assets/images/icons/icn_referral.png";
import EmailLogo from "../../../assets/images/icons/icn_email.png";
import EmailLock from "../../../assets/images/icons/icn_lock.png";
import Inputfield from "../../../components/ui/Inputfield";
import PasswordInput from "../../../components/ui/passwordinput";
import axios from "../../../helper/api/api_instance";
import ButtonData from "../../../components/ui/button";
import { Base_Route } from "../../../helper/constant";
import CompleteProfile from "../../CompleteProfile";
import FormModal from "../../../components/modals/FormModal";
const Signup = () => {
  const navigate = useNavigate();
  const MyComp = () => {
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(0);
    const [checked, setChecked] = useState(false);
    const [searchParams] = useSearchParams();
    const [form] = Form.useForm();

    useEffect(() => {
      if (searchParams.get("code")) {
        setValue(1);
        form.setFieldsValue({
          referralCode: searchParams.get("code"),
          role: '1'
        });
      }
    }, []);
    const onFinish = (values) => {
      if (checked === true) {
        setLoading(true);
        const fd = new FormData();
        fd.append("user_name", values?.name);
        fd.append("user_email_id", values?.email);
        fd.append("user_password", values?.password);
        {
          values?.referralCode &&
            fd.append("referral_code", values?.referralCode);
        }
        fd.append("user_type", values?.role === "1" ? "athlete" : "coach");
        axios
          .post(`user_register`, fd)
          .then((res) => {
            if (res?.status === 200 && res?.data?.responsecode === 1) {
              setLoading(false);
              localStorage.setItem(
                "AuthUser",
                JSON.stringify({
                  UserId: res.data.data.user_id,
                  Token: res.data.data.Token,
                })
              );
              localStorage.setItem(
                "UserProfile",
                JSON.stringify({
                  UserProfile: res.data.data,
                })
              );
              if (res?.data?.data?.user_type === "coach") {
                navigate(`${Base_Route}/trufit/coach-dashboard`);
              } else {
                navigate(`${Base_Route}/complete-profile`, {state : {skip_trial: false }});
              }
            } else {
              toastr.error(res.data.message);
              setLoading(false);
            }
          })
          .catch((error) => {
            toastr.error(error.message);
            setLoading(false);
          });
      } else {
        toastr.error("please check the Terms & Conditions");
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === " ") {
        e.preventDefault();
      }
    };

    const onChange = (e) => {
      setValue(e.target.value);
    };

    const onCheckChange = (e) => {
      setChecked(e.target.checked);
    };
    return (
      <>
        <img src={Logo} alt="trufit-logo" className="auth-logo" />
        <p className="lets-get-started mt-2">Let’s Get Started!</p>
        <div className="mt-3">
          <Form
            name="login"
            initialValues={{
              remember: true,
            }}
            form={form}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter your name",
                },
              ]}
            >
              <Inputfield
                prefix={
                  <img alt="" src={UserLogo} style={{ height: "20px" }} />
                }
                placeholder="Enter Name"
                className="login-input"
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter valid email",
                  validator: (_, value) => {
                    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Please input your E-mail!");
                    }
                  },
                },
              ]}
            >
              <Inputfield
                prefix={
                  <img alt="" src={EmailLogo} style={{ height: "20px" }} />
                }
                placeholder="Enter Email"
                className="login-input mt-3"
                onKeyDown={handleKeyDown}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
                { min: 8, message: "Password must be of minimum 8 characters" },
              ]}
            >
              <PasswordInput
                prefix={
                  <img alt="" src={EmailLock} style={{ height: "20px" }} />
                }
                placeholder="Enter Password"
                className="login-input mt-3"
              />
            </Form.Item>
            <Form.Item
              name="confirm-password"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match"
                      )
                    );
                  },
                }),
              ]}
            >
              <PasswordInput
                prefix={
                  <img alt="" src={EmailLock} style={{ height: "20px" }} />
                }
                placeholder="Confirm Password"
                className="login-input mt-3"
              />
            </Form.Item>
            {value === 1 && (
              <Form.Item
                name="referralCode"
                rules={[
                  {
                    required: false,
                    message: "Please enter your referrals code",
                  },
                ]}
              >
                <Inputfield
                  prefix={
                    <img alt="" src={ReferalLogo} style={{ height: "20px" }} />
                  }
                  placeholder="Referrals Code"
                  className="login-input mt-3"
                />
              </Form.Item>
            )}
            <Form.Item
              name="role"
              rules={[
                {
                  required: true,
                  message: "Please select a role",
                },
              ]}
            >
              <div className="d-flex align-items-center mt-2">
                <span className="sign-up-as">Sign Up As:</span>

                <Radio.Group
                  className="mx-3 mt-1"
                  onChange={onChange}
                  value={value}
                >
                  <Radio
                    value={1}
                    style={{ color: value === 1 ? "#007CBA" : "#526B78" }}
                    className="radio-value"
                  >
                    Athlete
                  </Radio>
                  <Radio
                    value={2}
                    style={{ color: value === 2 ? "#007CBA" : "#526B78" }}
                    className="radio-value"
                  >
                    Coach
                  </Radio>
                </Radio.Group>
              </div>
            </Form.Item>

            <Checkbox onChange={onCheckChange} className="no-accout mt-4">
              I agree to&nbsp;
              <strong className="yellow-underline">
                Terms & Conditions
              </strong>{" "}
              and&nbsp;
              <strong
                onClick={() => navigate(`${Base_Route}/privacy-policy`)}
                className="yellow-underline"
              >
                Privacy Policy.
              </strong>
            </Checkbox>
            <ButtonData
              loading={loading}
              htmltype="submit"
              type="authButton mt-2"
              // onClick={onFinish}
            >
              Sign Up
            </ButtonData>
            <p className="no-accout mt-2">
              Already have an account?
              <strong
                onClick={() => navigate("/")}
                className="yellow-underline"
              >
                &nbsp;Login
              </strong>
            </p>
          </Form>
        </div>
      </>
    );
  };

  return (
    <>
      <AuthFrame fields={<MyComp />} />;
    </>
  );
};

export default Signup;
