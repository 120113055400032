import React from "react";
const Login = (props) => {
  return (
    <>
      <div className="main-div">
        <div className="strech-man-div"></div>
        <div
          className="auth-form-div d-flex align-items-center justify-content-center"
          style={{ margin: "20px" }}
        >
          <div
            className="auth-form-inner"
            style={{
              width: "min-content",
              textAlign: "center",
              margin: "20px",
            }}
          >
            {props.fields}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
