import { useEffect, useState } from "react";
import toastr from "toastr";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonData from "../../../components/ui/button";
import axios from "../../../helper/api/api_instance";
import Spinner from "../../../components/ui/spinner";
import Athlete from "../../../components/ui/Athlete";
import {
  Base_Route,
  focusColors,
  focusBorder,
  images,
} from "../../../helper/constant";
import Focus from "../../../components/ui/Focus";
import StripeModal from "../../../components/modals/StripeModal";
import { Card } from "antd";
import Slider from "../../../components/ui/Slider";
const Team = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState();
  const [group, setGroup] = useState([]);
  const [groupLoading, setGroupLoading] = useState(true);
  const [groupDetail, setGroupDetail] = useState({});
  const [groupDetailLoading, setGroupDetailLoading] = useState(false);
  const checkFocus = localStorage.getItem("Focus");
  let count = -1;

  const coaches = groupDetail?.user_list?.filter(
    (x) => x.user_type === "coach"
  );
  const athletes = groupDetail?.user_list?.filter(
    (x) => x.user_type === "athlete"
  );

  useEffect(() => {
      getAllGroups();
  }, []);


  const getAllGroups = () => {
    axios
      .post(`athlete_profile`)
      .then(async (res) => {
        setGroupLoading(false);
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          if (location.state === null  && checkFocus === null) {
            setSelected(res?.data?.data?.social_data[0]?.group_id);
            getGroupDetail(res?.data?.data?.social_data[0]?.group_id);
          }else if (checkFocus !== null) {
            setSelected(JSON.parse(checkFocus)?.group_id);
            getGroupDetail(JSON.parse(checkFocus)?.group_id);
            localStorage.removeItem('Focus');
          } else {
            setSelected(location?.state?.group_id);
            getGroupDetail(location?.state?.group_id);
          }
          setGroup(res?.data?.data?.social_data);
        } else {
          toastr.error(res.message);
        }
      })
      .catch((error) => {
        setGroupLoading(false);
        toastr.error(error.message);
      });
  };

  const getGroupDetail = (id) => {
    setGroupDetailLoading(true);
    const fd = new FormData();
    fd.append("group_id", id);
    axios
      .post(`group_details`, fd)
      .then((res) => {
        setGroupDetailLoading(false);
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setGroupDetail(res?.data?.data);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        setGroupDetailLoading(false);
        toastr.error(error.message);
      });
  };

  const onSelectGroup = (id) => {
    setSelected(id);
    getGroupDetail(id);
  };

  const openDetailHandler = (id, type, profile, name) => {
    if (type === "coach") {
      navigate(`${Base_Route}/trufit/coach-profile`, {
        state: { id, profile, name, selected },
      });
    } else {
      navigate(`${Base_Route}/trufit/athlete-profile`, {
        state: { id, selected },
      });
    }
  };

  return (
    <>
      {groupLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner color="#d9d9d9" />
        </div>
      ) : (
        <>
          <div>
            <div style={{ marginRight: "20px", paddingTop: "25px" }}>
              <div className="d-flex justify-content-between ms-3">
                <p className="activity m-0">Teams</p>
                <div className="d-flex">
                  <ButtonData
                    type="join-btn"
                    onClick={() => navigate(`${Base_Route}/trufit/join-group`)}
                    style={{ width: "150px" }}
                  >
                    Join Group
                  </ButtonData>
                </div>
              </div>
              {groupLoading ? (
                <div
                  style={{
                    marginTop: "25px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <Spinner color="#d9d9d9" />
                </div>
              ) : group?.length > 0 ? (
                <Slider>
                  {group.map((x, i) => {
                    if (count === focusColors.length - 1) {
                      count = 0;
                    } else {
                      count++;
                    }
                    return (
                      <Focus
                        key={i}
                        selected={selected}
                        title={x.group_name}
                        id={x.group_id}
                        image={images[count]}
                        color={focusColors[count]}
                        borderColor={focusBorder[count]}
                        handleSelectFocus={onSelectGroup}
                      />
                    );
                  })}
                </Slider>
              ) : (
                <span className="d-flex align-items-center justify-content-center view-more pt-3 pb-1 activity-loader">
                  Create or join your first group
                </span>
              )}
              {groupDetailLoading ? (
                <div className="mt-5 d-flex justify-content-center">
                  <Spinner color="#d9d9d9" />
                </div>
              ) : groupDetail ? (
                <>
                  <div className=" mx-3 mt-5 d-flex justify-content-between align-items-center">
                    <p className="tips-heading">{groupDetail?.group_name}</p>
                    {groupDetail?.group_type === "is_private" && (
                      <p className="invite-code-heading">
                        Invite Code:{" "}
                        <span className="invite-code">
                          {groupDetail?.invite_code}
                        </span>
                      </p>
                    )}
                  </div>
                  <p className="tips-desc mx-3">
                    {groupDetail?.group_description}
                  </p>
                  {groupDetail?.request_status === "pending_request" ? (
                    <div className="d-flex justify-content-center mt-4">
                      <p className="tips-desc">
                        Your request to join this group is pending
                      </p>
                    </div>
                  ) : (
                    <div className="mx-3">
                      <p className="coaches mt-3">Coaches</p>
                      <div className="row">
                        {coaches?.length > 0 ? (
                          coaches?.map((x, i) => {
                            return (
                              <div key={i} className="col-md-6">
                                <Athlete
                                  profile={x.profile_pic}
                                  name={x.user_name}
                                  id={x.user_id}
                                  type={x.user_type}
                                  openDetailHandler={openDetailHandler}
                                  classname={"athlete-group-card"}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <div className="d-flex justify-content-center tips-desc">
                            {groupDetailLoading ? (
                              <Spinner color="#d9d9d9" />
                            ) : (
                              <p>No Coach Found</p>
                            )}
                          </div>
                        )}
                      </div>
                      <p className="coaches mt-3">Athletes</p>
                      <div className="row">
                        {athletes?.length > 0 ? (
                          athletes?.map((x, i) => {
                            return (
                              <div key={i} className="col-md-6">
                                <Athlete
                                  profile={x.profile_pic}
                                  name={x.user_name}
                                  id={x.user_id}
                                  type={x.user_type}
                                  openDetailHandler={openDetailHandler}
                                  classname={"athlete-group-card"}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <div className="d-flex justify-content-center tips-desc">
                            {groupDetailLoading ? (
                              <Spinner color="#d9d9d9" />
                            ) : (
                              <p>No Athlete Found</p>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <span className="d-flex align-items-center justify-content-center view-more pt-3 pb-1"></span>
              )}
            </div>
          </div>
        </>
      )}
    
    </>
  );
};

export default Team;
