import React from "react";
import FaqRequest from "../components/ui/faqRequest";
import { useState } from "react";
import { useEffect } from "react";
import axios from "../helper/api/api_instance";
import toastr from "toastr";
import Spinner from "../components/ui/spinner";

const FrequentlyAskedQuestions = () => {
  const [faq, setFaq] = useState([]);
  const [loading, setLoading] = useState(false);
  const getAllFaq = () => {
    setLoading(true);
    axios
      .post(`faq_list`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setFaq(res?.data?.data);
          setLoading(false);
        } else {
          toastr.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllFaq();
  }, []);
  return (
    <div style={{ padding: "25px 20px" }}>
      <p className="athlete-profile-name">Frequently Asked Questions</p>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner color="#d9d9d9" />
        </div>
      ) : (
        faq?.map((x, i) => {
          return <FaqRequest key={i} faq={x} />;
        })
      )}
    </div>
  );
};

export default FrequentlyAskedQuestions;
