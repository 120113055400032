import React, { useEffect, useState } from "react";
import Calendar from "../../assets/images/icons/icn_calendar.png";
import toastr from "toastr";
import axios from "../../helper/api/api_instance";
import { DatePicker, Radio } from "antd";
import { useLocation } from "react-router-dom";
import ProfileWorkout from "../../components/ui/ProfileWorkout";
import AssignWorkoutDetail from "../../components/ui/AssignWorkoutDetail";
import Spinner from "../../components/ui/spinner";
import FormModal from "../../components/modals/FormModal";
import Disabilities from "../../components/ui/disabilities";
import ButtonData from "../../components/ui/button";
import { FavoritesOptions, WorkoutOptions } from "../../helper/constant";
import dayjs from "dayjs";
import moment from "moment";

const CalendarWorkout = () => {
  const location = useLocation();
  const [workout, setWorkout] = useState("Completed_Workouts");
  const [favourite, setFavourite] = useState("workout");
  const [modal, setModal] = useState(false);
  const [date, setDate] = useState("");
  const [dateWise, setDateWise] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [athleteData, setAthleteData] = useState({});
  const [workoutState, setWorkoutState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDateWise, setLoadingDateWise] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);
  const [workoutDetail, setWorkoutDetail] = useState({});
  const [visible, setVisible] = useState(false);
  const { myDate } = location.state;

  const getAthleteData = (val) => {
    setLoading(true);
    const fd = new FormData();
    fd.append("workout_date", val);
    axios
      .post(`calendar_workout_list`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setAthleteData(res?.data?.data);
          setLoading(false);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const getWorkoutDetail = (val, assign_id) => {
    setDetailLoading(true);
    const fd = new FormData();
    fd.append("workout_id", val);
    if (workout === "Completed_Workouts") {
      fd.append("log_workout_id", assign_id);
    } else {
      fd.append("assign_workout_user_id", assign_id);
    }
    axios
      .post(`workout_details`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setWorkoutDetail(res?.data?.data);
          setDetailLoading(false);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const refreshCall = () => {
    setWorkout("Completed_Workouts");
    getAthleteData(myDate);
    setWorkoutState(false);
  };

  useEffect(() => {
    setDate(myDate);
    getAthleteData(myDate);
  }, []);

  const onChange4 = ({ target: { value } }) => {
    setWorkout(value);
    setDateWise(false);
    setWorkoutState(false);
  };

  const onChangeFav = ({ target: { value } }) => {
    setFavourite(value);
    setWorkoutState(false);
  };

  const onChange = (date, dateString) => {
    setDate(dateString);
  };

  const handleApplyDateFilter = () => {
    setModal(!modal);
    getAthleteData(date);
  };

  const viewDetailHandler = (workout_id, assign_id) => {
    getWorkoutDetail(workout_id, assign_id);
    setWorkoutState(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "80vh" }}
        >
          <Spinner color="#d9d9d9" />
        </div>
      ) : (
        <>
          <div className="m-4">
            <p className="athlete-profile-name mt-4">Workouts</p>
            <div className="row mt-4">
              <div className="col-sm-6 col-lg-6 col-xl-4 favourite-radio-group fav-radio">
                <Radio.Group
                  options={WorkoutOptions.slice(0, 2)}
                  onChange={onChange4}
                  value={workout}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
              <div className="col-xl-8 d-flex justify-content-end calendar-picker">
                <>
                  <DatePicker
                    disabledDate={(current) => {
                      return current && current > moment().endOf("day");
                    }}
                    open={modal}
                    defaultValue={dayjs(date)}
                    onChange={onChange}
                    showToday={false}
                    shouldCloseOnSelect={true}
                    renderExtraFooter={() => (
                      <div className="d-flex justify-content-end p-2">
                        <div className="d-flex justify-content-between">
                          <ButtonData
                            type="border-blue-btn"
                            onClick={() => {
                              setModal(!modal);
                              setDate(myDate);
                            }}
                          >
                            Cancel
                          </ButtonData>
                          <ButtonData
                            type="filled-blue-btn mx-2"
                            onClick={() => handleApplyDateFilter()}
                          >
                            Ok
                          </ButtonData>
                        </div>
                      </div>
                    )}
                  />
                  <div
                    onClick={() => setModal(!modal)}
                    className="d-flex white-square-bg p-3"
                  >
                    <p className="exercise-desc-2">{date}</p>
                    <img
                      src={Calendar}
                      alt="disable-people"
                      className="mx-2"
                      style={{ height: "20px" }}
                    />
                  </div>
                </>
              </div>
            </div>

            {(workout === "Assigned_Workouts" ||
              workout === "Completed_Workouts" ||
              (workout === "Favourites" && favourite === "workout")) &&
            workoutState === true ? (
              <AssignWorkoutDetail
                workout={workout}
                WorkoutLoading={detailLoading}
                detail={workoutDetail}
                setWorkoutState={setWorkoutState}
                refreshCall={refreshCall}
              />
            ) : workout === "Completed_Workouts" &&
              athleteData?.completed_workout_data?.length === 0 ? (
              <div
                style={{ height: "50vh" }}
                className="d-flex justify-content-center align-items-center"
              >
                {loadingDateWise ? (
                  <Spinner color="#d9d9d9" />
                ) : (
                  <p className="exercise-heading">No Workout Found!</p>
                )}
              </div>
            ) : workout === "Assigned_Workouts" &&
              athleteData?.assign_workout_data?.length === 0 ? (
              <div
                style={{ height: "50vh" }}
                className="d-flex justify-content-center align-items-center"
              >
                {loadingDateWise ? (
                  <Spinner color="#d9d9d9" />
                ) : (
                  <p className="exercise-heading">No Workout Found!</p>
                )}
              </div>
            ) : (
              <div className="row">
                {(workout === "Completed_Workouts" && dateWise === false
                  ? athleteData?.completed_workout_data
                  : workout === "Completed_Workouts" && dateWise === true
                  ? filterData
                  : workout === "Assigned_Workouts"
                  ? athleteData?.assign_workout_data
                  : workout === "Favourites" && favourite === "workout"
                  ? athleteData?.favourite_data?.get_fav_workout_data
                  : workout === "Favourites" && favourite === "exercises"
                  ? athleteData?.favourite_data?.get_fav_exercise_data
                  : undefined
                )?.map((x, i, arrayRef) => {
                  return (
                    <div key={i} className="col-lg-4">
                      <ProfileWorkout
                        title={x.workout_title}
                        description={x.long_desc}
                        id={x.workout_id}
                        date={
                          workout === "Completed_Workouts"
                            ? x.log_workout_date
                              ? x.log_workout_date
                              : date
                            : workout === "Assigned_Workouts"
                            ? x.created_at
                            : undefined
                        }
                        assignId={
                          workout === "Completed_Workouts"
                            ? x?.log_workout_id
                            : x?.assign_workout_user_id
                        }
                        workout={workout}
                        arrow={workout === "Completed_Workouts" ? false : true}
                        viewDetail={viewDetailHandler}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </>
      )}

      <FormModal
        title="Disabilities"
        visible={visible}
        onCancel={handleCancel}
        content={
          <Disabilities
            cancel={handleCancel}
            visible={visible}
            //  id={id}
          />
        }
      />
    </>
  );
};

export default CalendarWorkout;
