import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: "25px 20px" }} className="exercise-title-desc">
      <p className="athlete-profile-name">Privacy Policy</p>
      <p>
        This Privacy Policy explains our practices regarding the collection,
        use, disclosure, and protection of information that is collected through
        our Service, as well as your choices regarding the collection and use of
        information
      </p>
      <p>
        Prior to using the Service, kindly make sure you have read and agree
        with the terms of collection, storage, use and disclosure of your
        Personal Information as described in current Privacy Policy.
      </p>
      <p>
        If you choose to use our Service, then you agree to the collection and
        use of information in relation to this policy. The Information that we
        collect is used for providing and improving the Service. We will not use
        or share your information with anyone except as described in this
        Privacy Policy.
      </p>
      <p>
        The app does use third party services that may collect information used
        to identify you.
      </p>
      <p className="faq-bold">Information Collection and Use</p>
      <p>
        For a better experience, while using our Service, we may require you to
        provide us with certain personally identifiable information. The
        information that we request will be retained by us and used as described
        in this privacy policy.
      </p>
      <p>
        When you use the Service, some information about your device and your
        user behavior may be processed automatically. This information is
        generally non-personal, and we may access it only in aggregated form. We
        process this information on the grounds of our legitimate interest in
        improving our Website/App and giving our users the best experience.
        Otherwise we may not be able to provide you with all the requested
        services and features of the App.
      </p>
      <p>
        <strong className="faq-bold">Personal Information</strong> - when
        creating an account, you may be asked to provide us personal information
        such as your name, surname, username, e-mail address, phone number,
        photo, etc. If you connect a family member or friend to your account, we
        may collect the same personal information about them too. When signing
        up for a paid service within our website/app, we may also collect and
        store billing information. Personal Information may be required for
        account authentication, when dealing with inquires and complaints made
        by or about you related to the Service, for communication with you,
        including sending service-related messages, sending unsolicited
        marketing notifications, maintenance and provision of features and
        functionality of the Service, personalizing your profile in order to
        interact, and be recognized by users in your Groups.
      </p>
      <p>
        When using our Service, we may also collect information regarding
        addresses and locations of you and your friends/family in order to mark
        your favorite places and generate alerts when family/friends leave and
        arrive.
      </p>
      <p>
        <strong className="faq-bold">Geolocation</strong> -We collect your
        location information through your device IP Address, Wi-Fi, Bluetooth,
        and GPS coordinates available through your mobile device. We may also
        collect data about your location using other technologies and sensors
        which may provide us with information about nearby devices, Wi-Fi access
        points and networks, and nearby cell towers.
      </p>
      <p>
        We collect and maintain location data to provide you with complete
        functionality of the Service. We may share location information with
        third parties for advertising, research, analytics, and other purposes.
        If you want to restrict collection of your location data, please adapt
        your settings in your mobile device, to bound the access of the app.
      </p>
      <p>
        The Service may also collect sensory and motion data from your
        smartphone, in order to calculate and detect driving events. This
        information may be shared with third party partners for marketing,
        research, analytics, and other purposes. If you want to bound the access
        of the app to your sensory and motion data, please adjust the settings
        in your device.
      </p>
      <p>
        In order to operate, maintain and provide you with the features and
        functionality of the Service, we may collect and store data when you
        message other members of your Groups. Also, when you connect with us
        through social media, we may receive such information as public profile
        data, contacts, and any other data you allow your social network to
        share. The data we receive may be restricted by you, when adjusting your
        privacy settings with the social network.
      </p>
      <p className="faq-bold">Log Data</p>
      <p>
        We want to inform you that whenever you use our Service, in case of an
        error in the app we collect data and information (through third party
        products) on your phone called Log Data. This Log Data may include
        information such as your device IP address, device name, operating
        system version, the configuration of the app when utilizing our Service,
        the time and date of your use of the Service, and other statistics.
      </p>
      <p className="faq-bold">Cookies</p>
      <p>
        Cookies are files with a small amount of data that are commonly used as
        anonymous unique identifiers. These are sent to your browser from the
        websites that you visit and are stored on your device's internal memory.
      </p>
      <p style={{ color: "rgb(236,124,48)" }}>
        This Service does not use these "cookies" explicitly. However, the app
        may use third party code and libraries that use "cookies" to collect
        information and improve their services. You have the option to either
        accept or refuse these cookies and know when a cookie is being sent to
        your device. If you choose to refuse our cookies, you may not be able to
        use some portions of this Service.
      </p>
      <p>
        <strong className="faq-bold">
          The Purpose of Collecting Your Personal Data
        </strong>{" "}
        - Our mission is to constantly improve our Service and provide new
        features. We use collected data to:
      </p>
      <div className="mx-5 d-flex flex-column">
        <span>1. Provide the requested service</span>
        <span>
          2. Improve, test, and monitor the effectiveness of the Service
        </span>
        <span>
          3. Provide interest-based advertising or other targeted content
        </span>
        <span>4. For Communication</span>
        <span>5. To prevent spam, fraud and enforce of law</span>
      </div>
      <p className="mt-3">
        <strong className="faq-bold">Sharing Collected Information</strong> - We
        may share the collected data with third parties only for purposes
        described under this Privacy Policy. We will not rent or sell your
        personal data to third parties. We may, however, share information like
        cookies, log files, device identifiers and location data, with third
        party organizations that provide automatic data processing technologies
        for the Service.
      </p>
      <p>
        We may also share certain information such as cookie data, with third
        parties, for advertising purposes.
      </p>
      <p>
        The Service may contain links to third-party services, or you may access
        the Service from a third-party site. We are not responsible for the
        privacy practices of these sites or services linked to or from our
        Service, including the information or content contained within them.
      </p>
      <p className="faq-bold">Service Providers</p>
      <p>
        We may employ third-party companies and individuals due to the following
        reasons:
      </p>
      <ul>
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used</li>
      </ul>
      <p>
        We want to inform our users of this Service that these third parties
        have access to your Personal Information. The reason is to perform the
        tasks assigned to them on our behalf. However, they are obligated not to
        disclose or use the information for any other purpose.
      </p>
      <p className="faq-bold">Security</p>
      <p>
        We value your trust in providing us with your Personal Information, thus
        we are striving to use commercially acceptable means of protecting it.
      </p>
      <p>
        We take reasonable and appropriate measures to protect personal
        information from loss, misuse, unauthorized access, disclosure,
        alteration, and destruction, considering the risks involved in the
        processing and nature of the personal information. We implement
        appropriate technical and organizational measures to assure
        data-protection principles and encryption with strong algorithms.{" "}
      </p>
      <p>
        Unfortunately, no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and we cannot guarantee
        its absolute security. If your personal data is compromised by a breach
        of security, we will promptly notify you in compliance with applicable
        law.
      </p>
      <p className="faq-bold">Links to Other Sites</p>
      <p>
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by us. Therefore, we strongly advise you
        to review the Privacy Policy of these websites. We have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </p>
      <p>
        <strong className="faq-bold">California Privacy Rights</strong> -
        Residents of State of California (USA) have the right to request a list
        of all the third parties to whom our Service may disclose certain
        personal information (in accordance with the laws of State of
        California) for the purpose of marketing and advertising. If you are a
        resident of the State of California and want to receive such a list,
        please contact us{" "}
        <span className="faq-blue-underline">atinfo@gettrufit.com</span>. When
        submitting such a request, please include in the Subject Line
        "California Privacy Rights", as well as your name, address, city, state,
        and zip code. You must be able to confirm that you are a resident of the
        State of California (USA) and provide all required details.
      </p>
      <p className="faq-bold">Children’s Privacy </p>
      <p>
        The Service is intended to be used by children, only with the consent of
        parents/guardians. If so, the child’s personal information, including
        name, surname, email, and geolocation will be shared with other members
        of the Groups. The Service may collect, use, disclose and store the
        child’s personal information.{" "}
      </p>
      <p>
        {" "}
        However, a parent may restrict the usage and disclosure of personal
        information of their child by contacting us at{" "}
        <span className="faq-blue-underline">info@gettrufit.com</span>
      </p>
      <p className="faq-bold">Changes to This Privacy Policy</p>
      <p>
        We may update our Privacy Policy from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Privacy Policy on this page. These
        changes are effective immediately after they are posted on this page.
      </p>

      <p className="mt-4">© Copyright TruFit, LLC 2023. All rights reserved</p>
    </div>
  );
};

export default PrivacyPolicy;
