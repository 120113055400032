import React, { useEffect, useState } from "react";
import WorkoutCard from "../../../components/ui/WorkoutCard";
import exerciseImg from "../../../assets/images/core/Feed-img.png";
import { Card, Typography } from "antd";
import ButtonData from "../../../components/ui/button";
import FormModal from "../../../components/modals/FormModal";
import LogWorkoutModal from "../../../components/modals/LogWorkoutModal";
import axios from "../../../helper/api/api_instance";
import toastr from "toastr";
import Spinner from "../../../components/ui/spinner";
import Detail from "../../../components/ui/ExerciseDetail";
import StripeModal from "../../../components/modals/StripeModal";
import Search from "../../../assets/images/icons/icn_search.png";
import Inputfield from "../../../components/ui/Inputfield";
const { Text } = Typography;
const AthleteWorkout = () => {
  const [visible, setVisible] = useState(false);
  const [exercises, setExercises] = useState([]);
  const [workout, setWorkout] = useState([]);
  const [selected, setSelected] = useState();
  const [currentWorkout, setCurrentWorkout] = useState("true");
  const [workoutLoading, setWorkoutLoading] = useState(true);
  const [workoutDetail, setWorkoutDetail] = useState({});
  const [workoutDetailLoading, setWorkoutDetailLoading] = useState(false);
  const [exerciseCheck, setExerciseCheck] = useState(false);
  const [exerciseId, setExerciseId] = useState();
  const [loading, setLoading] = useState(false);
  const [favLoading, setFavLoading] = useState({});
  const [search, setSearch] = useState("");

  console.log('workout detail===+>>>', workoutDetail);

  const onSelectWorkoutHandler = (id, curr) => {
    setExerciseCheck(false);
    setSelected(id);
    setCurrentWorkout(curr);
    getWorkoutDetail(id);
  };

  const getWorkoutDetail = (id) => {
    setWorkoutDetailLoading(true);
    const fd = new FormData();
    fd.append("workout_id", id);
    axios
      .post(`workout_details`, fd)
      .then((res) => {
        setWorkoutDetailLoading(false);
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setWorkoutDetail(res?.data?.data);
        } else {
          setWorkoutDetailLoading(false);
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        setWorkoutDetailLoading(false);
        toastr.error(error.message);
      });
  };

  const getWorkouts = () => {
    setWorkoutLoading(true);
    axios
      .post(`find_workout_list`)
      .then((res) => {
        setWorkoutLoading(false);
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setWorkout(res?.data?.data);
          const first_workout = res?.data?.data?.filter(
            (x) => x?.is_video_live === "true"
          )[0]?.workout_id;

          console.log('first_workout==>>>', first_workout);
          setSelected(first_workout);
          getWorkoutDetail(first_workout);
        } else {
          setWorkoutLoading(false);
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        setWorkoutLoading(false);
      });
  };

  useEffect(() => {
      getWorkouts();
  }, []);

  const getVideoLink = () => {
    let link = null;
    if (workoutDetail != null) {
      link =
        "https://www.youtube.com/embed/" +
        workoutDetail?.live_video_url?.split("=")[1];
    }

    return link;
  };

  const getExerciseDetail = (id) => {
    setExerciseId(id);
    setExerciseCheck(true);
  };

  const logWorkoutHandler = (ids) => {
    setLoading(true);
    const fd = new FormData();
    fd.append("exercise_id", ids);
    axios
      .post(`exercise_adjustment_list`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setExercises(res?.data?.data);
          setVisible(true);
          setLoading(false);
        } else {
          toastr.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setLoading(false);
      });
  };

  const onFavourite = async (e, id, status) => {
    e.stopPropagation();
    const duplicateWorkout = [...workout];
    const index = await duplicateWorkout.findIndex(
      ({ workout_id }) => workout_id === id
    );
    setFavLoading(id);

    const fd = new FormData();
    fd.append("workout_id", id);
    fd.append("is_favourite", status === "true" ? "false" : "true");
    axios
      .post(`favourite_workout`, fd)
      .then((res) => {
        setFavLoading(-1);
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          duplicateWorkout[index].fav_status =
            status === "true" ? "false" : "true";
          setWorkout(duplicateWorkout);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };


  const isWorkoutDetailNotEmpty = Object.keys(workoutDetail).length > 0;
  if(isWorkoutDetailNotEmpty) {
    console.log('data present');
  }else{
    console.log('data not present');
  }
  return (
    <>
      {workoutLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner color="#d9d9d9" />
        </div>
      )  : (
        <>
          <div className="workout-main-div">
            <div className="workout-side-div">
              <p className="athlete-profile-name mt-3">Workout</p>
              <Inputfield
                suffix={<img alt="" src={Search} style={{ height: "15px" }} />}
                placeholder="Search Here"
                className="input-header  mb-2"
                style={{width:'100%'}}
                onChange={(e) => setSearch(e.target.value)}
              />
              <p className="upcoming-live">Up Coming / Live</p>
              {workoutLoading ? (
                <div className="d-flex justify-content-center">
                  <Spinner color="#d9d9d9" />
                </div>
              ) : (
                workout
                  ?.filter((post) => {
                    if (search === "") {
                      return post;
                    } else if (
                      post.workout_title
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return post;
                    }
                  })
                  ?.filter((x) => x?.is_video_live === "true")
                  ?.map((x, i) => {
                    return (
                      <WorkoutCard
                        key={i}
                        selected={selected}
                        id={x?.workout_id}
                        name={x?.workout_title}
                        description={x?.long_desc}
                        current={x?.is_video_live}
                        thumb={x?.live_video_thumb}
                        status={x?.fav_status}
                        onFavourite={onFavourite}
                        onSelectWorkout={onSelectWorkoutHandler}
                        favLoading={favLoading && x.workout_id === favLoading}
                      />
                    );
                  })
              )}
              <p className="created-by-coaches mt-3">Created by Coaches</p>
              {workoutLoading ? (
                <div className="d-flex justify-content-center">
                  <Spinner color="#d9d9d9" />
                </div>
              ) : (
                workout
                  ?.filter((post) => {
                    if (search === "") {
                      return post;
                    } else if (
                      post.workout_title
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return post;
                    }
                  })
                  ?.filter((x) => x?.is_video_live === "false")
                  ?.map((x, i) => {
                    return (
                      <WorkoutCard
                        key={i}
                        selected={selected}
                        id={x?.workout_id}
                        type="createdByCoach"
                        name={x?.workout_title}
                        description={x?.long_desc}
                        current={x?.is_video_live}
                        status={x?.fav_status}
                        onSelectWorkout={onSelectWorkoutHandler}
                        onFavourite={onFavourite}
                        favLoading={favLoading && x.workout_id === favLoading}
                      />
                    );
                  })
              )}
            </div>
            {exerciseCheck ? (
              <div className="p-4 workout-detail-div">
                <Detail
                  id={exerciseId}
                  type="workout"
                  setExerciseCheck={setExerciseCheck}
                />
              </div>
            ) : (
              <div className="p-2 mt-4 mx-2 workout-detail-div">
                {workoutDetailLoading ? (
                  <div
                    className="d-flex justify-content-center  align-items-center"
                    style={{ height: "100vh" }}
                  >
                    <Spinner color="#d9d9d9" />
                  </div>
                ) : (
                  <>
                  {!isWorkoutDetailNotEmpty && <p className="workout-heading-detail text-center"> No Up Coming / Live Session </p>}
                  {isWorkoutDetailNotEmpty &&
                    currentWorkout !== "false" && getVideoLink() ? (
                      <iframe
                        src={getVideoLink()}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title="video"
                        className="youtube-video"
                        style={{
                          borderRadius: "25px",
                          objectFit: "cover",
                          top: "0",
                          bottom: "0",
                          left: "0",
                          right: "0",
                        }}
                      />
                    ) : workoutDetail?.live_video_thumb ? (
                      <img
                        src={workoutDetail?.live_video_thumb}
                        alt="exercise-detail"
                        className="youtube-video"
                        style={{
                          borderRadius: "25px",
                          objectFit: "cover",
                        }}
                      />
                    ) : null}

                    <p className="workout-head mt-2">
                      {workoutDetail?.workout_title}
                    </p>

                    <p className="exercise-desc">{workoutDetail?.long_desc}</p>
                    {currentWorkout === "true" && isWorkoutDetailNotEmpty && (
                      <p className="workout-heading-detail">
                        Similar Exercises
                      </p>
                    )}
                    <div className="row gx-3 gy-3 ">
                      {workoutDetail?.get_exercise_data?.map((x, i) => {
                        return (
                          <div
                            className="col-md-12 col-lg-6 col-xl-4 cursor-pointer"
                            key={i}
                            onClick={() => getExerciseDetail(x?.exercise_id)}
                          >
                            <div className="workout-exercise-card">
                              <img
                                src={
                                  x?.exercise_images[0]
                                    ? x?.exercise_images[0]?.exercise_image
                                    : exerciseImg
                                }
                                style={{
                                  height: "55px",
                                  width: "80px",
                                  borderRadius: "10px",
                                }}
                                alt="exercise"
                              />
                              <div className=" mx-2 d-flex flex-column justify-content-center">
                                <Text
                                  className="notes mb-0"
                                  style={{
                                    width: 200,
                                  }}
                                  ellipsis={true}
                                >
                                  {x?.exercise_name}
                                </Text>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  {isWorkoutDetailNotEmpty && (
                      <ButtonData
                      onClick={() =>
                        logWorkoutHandler(workoutDetail?.exercise_id)
                      }
                      type="mt-4 assign-btn"
                      style={{ width: "auto" }}
                      loading={loading}
                    >
                      Log Completed Workout
                    </ButtonData>
                  )}
                  </>
                )}
              </div>
            )}
          </div>
        </>
      )}
      <FormModal
        visible={visible}
        onCancel={() => setVisible(false)}
        content={
          <LogWorkoutModal
            workoutId={selected}
            exercise_ids={workoutDetail?.exercise_id}
            handleCancel={() => setVisible(false)}
            exercises={exercises}
            setVisible={setVisible}
          />
        }
      />
    </>
  );
};

export default AthleteWorkout;
