import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import Notification from "../../assets/images/icons/icn_notifications.png";
import AthletePic from "../../assets/images/core/Athlete.png";
import greenDot from "../../assets/images/icons/icn_notification_dot.png";
import axios from "../../helper/api/api_instance";
import toastr from "toastr";
import Spinner from "./spinner";
import { Base_Route } from "../../helper/constant";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(relativeTime);

const timeZone = dayjs.tz.guess();
const NotificationsDropdown = () => {
  const navigate = useNavigate();
  const [notification, setNotification] = useState([]);
  const [notificationLoading, setNotificationLoading] = useState(false);

  const getAllNotification = () => {
    setNotificationLoading(true);
    axios
      .post("notification_list")
      .then(async (res) => {
        setNotificationLoading(false);
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setNotification(res?.data?.data);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        setNotificationLoading(false);
        toastr.error(error.message);
      });
  };

  const items = [
    {
      label: (
        <div
          style={
            notificationLoading
              ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "30vh",
                }
              : {}
          }
        >
          {notificationLoading ? (
            <Spinner color="#d9d9d9" />
          ) : (
            <>
              <div className="d-flex align-items-center text-decoration-none notification-heading-div">
                <p className="m-3 notification-heading">Notifications</p>
              </div>
              {notification.length > 0 ? (
                notification?.slice(0, 5)?.map((x, i) => {
                  return (
                    <div
                      key={i}
                      className="single-notification mx-2"
                      onClick={() =>
                        navigate(`${Base_Route}/trufit/notifications`, {
                          state: { notification: notification },
                        })
                      }
                    >
                      <div className="d-flex">
                        <img
                          src={
                            x?.request_profile_pic
                              ? x?.request_profile_pic
                              : AthletePic
                          }
                          alt="profile-pic"
                          style={{
                            height: "50px",
                            width: "50px",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                        />
                        <div className="mx-2">
                          <span className="notification-description">
                            {x?.notification_message}
                          </span>
                        </div>
                      </div>

                      <div className="d-flex align-items-center justify-content-end">
                        <p className="notification-time-ago">
                          {dayjs(
                            new Date(
                              dayjs
                                .utc(x?.notification_create_date)
                                .tz(timeZone)
                            )
                          ).fromNow()}
                        </p>
                        <img
                          src={greenDot}
                          className="mb-3 ms-1"
                          alt="green-dot"
                          style={{ height: "5px" }}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <span className="d-flex align-items-center justify-content-center view-more pt-3 pb-1">
                  No notficiations yet
                </span>
              )}

              <div className="d-flex align-items-center justify-content-center text-decoration-none pt-2 pb-1">
                {notification.length > 0 ? (
                  <span
                    className="m-2 view-more"
                    onClick={() =>
                      navigate(`${Base_Route}/trufit/notifications`)
                    }
                  >
                    View More
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </div>
      ),
    },
  ];
  return (
    <Dropdown
      style={{ borderRadius: "10px" }}
      menu={{
        items,
      }}
      overlayClassName="notification-dropdown"
      trigger={["click"]}
      placement="bottomRight"
      arrow
    >
      <div
        onClick={() => getAllNotification()}
        className="d-flex align-items-center justify-content-center noti-div"
      >
        <img src={Notification} alt="noti-pic" style={{ height: "20px" }} />
      </div>
    </Dropdown>
  );
};

export default NotificationsDropdown;
