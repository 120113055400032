import axios from "axios";
import { Base_Route } from "../constant";
import toastr from "toastr";
const instance = axios.create({
  baseURL: process.env.REACT_APP_NODE_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    const Auth = localStorage.getItem("AuthUser");
    if (Auth !== null) {
      config.headers = JSON.parse(localStorage.getItem("AuthUser"));
      config.headers["Content-Type"] = "application/json";
      config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response?.data?.responsecode === 101) {
      localStorage.clear();
      return (window.location.href = Base_Route);
    } else {
      return response;
    }
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
      return (window.location.href = Base_Route);
    } else if (error?.response?.status !== 400) {
      toastr.error("Something went wrong");
    }
  }
);

export default instance;
