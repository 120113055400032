import { Form } from "antd";
import React from "react";
import PasswordInput from "./passwordinput";
import ButtonData from "./button";
import EmailLock from "../../assets/images/icons/icn_lock.png";
import toastr from "toastr";
import axios from "../../helper/api/api_instance";
import { useState } from "react";
const ChangePassword = ({ handleCancel }) => {
  const [loading, setLoading] = useState(false);
  const UserProfile = JSON.parse(
    localStorage.getItem("UserProfile")
  ).UserProfile;

  const onFinish = (values) => {
    setLoading(true);
    const fd = new FormData();
    fd.append("password_status", "change_password_status");
    fd.append("user_email_id", UserProfile?.user_email_id);
    fd.append("old_password", values?.currentPassword);
    fd.append("new_password", values?.newPassword);
    fd.append("confirm_new_password", values?.confirmPassword);

    axios
      .post("change_password", fd)
      .then(async (res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          toastr.success("Your password has been updated");
          handleCancel();
          setLoading(false);
        } else {
          toastr.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setLoading(false);
      });
  };
  return (
    <>
      <div className="mt-4 text-center m-5" style={{ width: "400px" }}>
        <p className="forgot-heading mt-4 mb-0">Change Password</p>
        <p className="login-des mx-4 mt-2" style={{ color: "#0A364B" }}>
          Enter your current password and new password to change password.
        </p>
        <Form
          name="login"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="currentPassword"
            rules={[
              {
                required: true,
                message: "Please enter your current password",
              },
              {
                min: 8,
                message: "Current Password must be atleast 8 characters",
              },
            ]}
          >
            <PasswordInput
              prefix={<img alt="" src={EmailLock} style={{ height: "20px" }} />}
              placeholder="Enter Current Password"
              className="login-input mt-3"
            />
          </Form.Item>
          <Form.Item
            name="newPassword"
            dependencies={["currentPassword"]}
            rules={[
              {
                required: true,
                message: "Please enter your new password",
              },
              { min: 8, message: "New Password must be atleast 8 characters" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("currentPassword") !== value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("New password cannot be the same as old password")
                  );
                },
              }),
            ]}
          >
            <PasswordInput
              prefix={<img alt="" src={EmailLock} style={{ height: "20px" }} />}
              placeholder="Enter Password"
              className="login-input mt-3"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={["newPassword", "currentPassword"]}
            rules={[
              {
                required: true,
                message: "Please confirm your new password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("New Password and confirm password must be same.")
                  );
                },
                validator(_, value) {
                  if (!value || getFieldValue("currentPassword") !== value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("New password cannot be the same as old password")
                  );
                },
              }),
            ]}
          >
            <PasswordInput
              prefix={<img alt="" src={EmailLock} style={{ height: "20px" }} />}
              placeholder="Confirm Password"
              className="login-input mt-3"
            />
          </Form.Item>
          <ButtonData
            htmltype="submit"
            type="authButton mt-4"
            loading={loading}
          >
            Update Password
          </ButtonData>
        </Form>
      </div>
    </>
  );
};

export default ChangePassword;
