import React from "react";
import ModalCross from "../../assets/images/icons/icn_close.png";
import FilterCard from "../ui/FilterCard";
import { useState } from "react";
import ButtonData from "../ui/button";

const FilterModal = ({ filter, handleCancel, getExercises, setVisible }) => {
  const [objFilter, setObjFilter] = useState([]);
  let keyArr = [];
  let valueArr = [];
  const getFilterArr = (val) => {
    Object.entries(val).forEach(([key, value]) => {
      keyArr.push(key);
      valueArr.push(value);
    });
  };

  if (filter !== undefined) {
    getFilterArr(filter);
  }
  const onCheckChange = (val, id, filter_name) => {
    if (val === true) {
      setObjFilter([
        ...objFilter,
        { filter_id: id, filter_title: filter_name },
      ]);
    } else {
      const indexofObj = objFilter?.findIndex(
        (x) => x.filter_title === filter_name && x.filter_id === id
      );
      objFilter.splice(indexofObj, 1);
    }
  };

  const getSingleFilter = (val) => {
    return objFilter
      ?.filter((x) => x.filter_title === val)
      ?.map((x) => x?.filter_id)
      .toString();
  };
  const applyFilterHandler = () => {
    let obj = {
      ability: getSingleFilter("disability"),
      equipment: getSingleFilter("equipment"),
      focus: getSingleFilter("fouce"),
    };
    getExercises(obj, "by_filter");
    setVisible(false);
  };

  const getFilterFormData = (val, name) => {
    const copyArr = val.map((x, i) => {
      return {
        filter_id: x[name + "_id"],
        filter_title:
          name === "disability" ? x[name + "_name"] : x[name + "_title"],
      };
    });
    return copyArr;
  };

  return (
    <>
      <div>
        <p className="athlete-profile-name line-height-51">Filters</p>
        <img
          src={ModalCross}
          alt="modal-cross"
          className="modal-cross"
          onClick={() => handleCancel()}
        />
      </div>
      <div className="exercises-scroll-div">
        {valueArr.length !== 0 &&
          valueArr?.map((x, i) => {
            return (
              <FilterCard
                data={getFilterFormData(x, keyArr[i]?.split("_")[0])}
                name={keyArr[i]?.split("_")[0]}
                onCheckChange={onCheckChange}
              />
            );
          })}
      </div>
      <div className="mt-2 d-flex justify-content-end">
        <ButtonData type="assign-btn" onClick={() => applyFilterHandler()}>
          Apply
        </ButtonData>
      </div>
    </>
  );
};

export default FilterModal;
