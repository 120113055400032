import React, { useEffect, useState } from "react";
import ButtonData from "../../../components/ui/button";
import axios from "../../../helper/api/api_instance";
import toastr from "toastr";
import WorkoutCard from "../../../components/ui/Workout";
import { useNavigate } from "react-router";
import Spinner from "../../../components/ui/spinner";
import FormModal from "../../../components/modals/FormModal";
import AssignWorkoutModal from "../../../components/modals/AssignWorkoutModal";
import { Base_Route } from "../../../helper/constant";
import Inputfield from "../../../components/ui/Inputfield";
import Search from "../../../assets/images/icons/icn_search.png";

const Workout = () => {
  const [workout, setWorkout] = useState([]);
  const [workoutLoading, setWorkoutLoading] = useState(false);
  const [exercises, setExercises] = useState([]);
  const [athlete, setAthlete] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [workoutId, setWorkoutId] = useState("");
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const getAllWorkout = () => {
    setWorkoutLoading(true);
    const fd = new FormData();
    fd.append(
      "login_user_id",
      JSON.parse(localStorage.getItem("AuthUser")).UserId
    );

    axios
      .post(`find_workout_list`, fd)
      .then((res) => {
        setWorkoutLoading(false);
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setWorkout(res?.data?.data);
        }
      })
      .catch((error) => {
        setWorkoutLoading(false);
        toastr.error(error.message);
      });
  };

  useEffect(() => {
    getAllWorkout();
    getCoachAthlete();
  }, []);

  const getCoachAthlete = () => {
    axios
      .post(`coach_athlete_list`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setAthlete(res?.data?.data);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const assignWorkoutHandler = (val, id) => {
    setWorkoutId(id);
    setLoading(true);
    const fd = new FormData();
    fd.append("exercise_id", val);
    axios
      .post(`exercise_adjustment_list`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setExercises(res?.data?.data);
          setVisible(true);
          setLoading(false);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <div style={{ marginRight: "20px", paddingTop: "25px" }}>
        <div className="d-flex justify-content-between align-items-center">
          <p className="activity">Workouts</p>
          <ButtonData
            onClick={() =>
              navigate(`${Base_Route}/trufit/add-workout`, {
                state: { label: "Create" },
              })
            }
            type="filled-blue-btn"
          >
            Create Workout
          </ButtonData>
        </div>
        <Inputfield
          suffix={<img alt="" src={Search} style={{ height: "15px" }} />}
          placeholder="Search Here"
          className="input-header mx-2"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div>
          {workoutLoading ? (
            <>
              <div className="mt-5 d-flex justify-content-center align-items-center">
                <Spinner color="#d9d9d9" />
              </div>
            </>
          ) : (
            <div className="row" style={{ marginLeft: "0px" }}>
              {workout?.length > 0 ? (
                workout
                  ?.filter((post) => {
                    if (search === "") {
                      return post;
                    } else if (
                      post.workout_title
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return post;
                    }
                  })
                  ?.map((x, i) => {
                    return (
                      <div key={i} className="col-lg-6">
                        <WorkoutCard
                          id={x.workout_id}
                          title={x.workout_title}
                          description={x.long_desc}
                          onClickButton={assignWorkoutHandler}
                          code={x.exercise_id}
                          loading={loading}
                          selectedWorkout={workoutId}
                          fullWorkout={x}
                          workout={workout}
                          setWorkout={setWorkout}
                        />
                      </div>
                    );
                  })
              ) : (
                <span className="d-flex align-items-center justify-content-center view-more pt-3 pb-1 activity-loader">
                  Create your first workout
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      <FormModal
        visible={visible}
        onCancel={handleCancel}
        content={
          <AssignWorkoutModal
            workoutId={workoutId}
            handleCancel={handleCancel}
            exercises={exercises}
            athlete={athlete}
            setAthlete={setAthlete}
            setVisible={setVisible}
          />
        }
      />
    </>
  );
};

export default Workout;
