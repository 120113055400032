import React, { useRef } from "react";
import ArrowBlueBg from "../../assets/images/icons/icn_scroll_arrow_blue.png";
import Spinner from "./spinner";

const Slider = ({ loading, children }) => {
  const ref = useRef(null);
  let scrollLeftState = 0;
  let isFirstTime = true;

  const Scroll = (scrollOffset) => {
    if (isFirstTime) {
      isFirstTime = false;
      scrollLeftState = ref.current.scrollLeft;
      ref.current.scrollLeft += 500;
    } else if (!isFirstTime && scrollLeftState !== ref.current.scrollLeft) {
      scrollLeftState = ref.current.scrollLeft;
      ref.current.scrollLeft += 500;
    } else {
      if (scrollLeftState === ref.current.scrollLeft) {
        ref.current.scrollLeft = 0;
        scrollLeftState = 0;
        isFirstTime = true;
      }
    }
  };

  const ScrollLeft = (scrollOffset) => {
    if (isFirstTime) {
      isFirstTime = false;
      scrollLeftState = ref.current.scrollLeft;
      ref.current.scrollLeft -= 500;
    } else if (!isFirstTime && scrollLeftState !== ref.current.scrollLeft) {
      scrollLeftState = ref.current.scrollLeft;
      ref.current.scrollLeft -= 500;
    } else {
      if (scrollLeftState === ref.current.scrollLeft) {
        ref.current.scrollLeft = 0;
        scrollLeftState = 0;
        isFirstTime = true;
      }
    }
  };
  return (
    <>
      <div className="slider">
        {loading ? (
          <Spinner color="#d9d9d9" />
        ) : (
          <>
            <img
              style={{ position: "absolute", left: "-14px" }}
              className="move-scroll-left"
              onClick={ScrollLeft}
              src={ArrowBlueBg}
              alt="arrow-white"
            />
            <div className="focus-scroll-div" ref={ref}>
              {children}
            </div>
            <img
              style={{ position: "absolute", right: "-14px" }}
              className="move-scroll"
              onClick={Scroll}
              src={ArrowBlueBg}
              alt="arrow-white"
            />
          </>
        )}
      </div>
    </>
  );
};

export default Slider;
