import React, { useState, useEffect } from "react";
import Feed from "./Feed";
import axios from "../../helper/api/api_instance";
import toastr from "toastr";
import Spinner from "./spinner";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(relativeTime);

const timeZone = dayjs.tz.guess();
const Activity = () => {
  const [feed, setFeed] = useState([]);
  const [feedLoading, setFeedLoading] = useState(false);
  const [favLoading, setFavLoading] = useState({});

  useEffect(() => {
    getFeed();
  }, []);

  const getFeed = () => {
    setFeedLoading(true);
    axios
      .post(`feed_list`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setFeed(res?.data?.data);
          setFeedLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const onFavourite = async (id, status) => {
    const duplicateFeed = [...feed];
    const index = await duplicateFeed.findIndex(
      ({ feed_id }) => feed_id === id
    );
    setFavLoading(id);

    const fd = new FormData();
    fd.append("feed_id", id);
    fd.append("is_favourite", status === "true" ? "false" : "true");
    axios
      .post(`favourite_feed`, fd)
      .then((res) => {
        setFavLoading(-1);
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          duplicateFeed[index].fav_status =
            status === "true" ? "false" : "true";
          duplicateFeed[index].fav_status =
            status === "true" ? "false" : "true";
          setFeed(duplicateFeed);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };
  return (
    <div
      className={feedLoading ? "activity-loader" : "activity-scroll-div"}
      style={{ marginRight: "15px", paddingTop: "25px" }}
    >
      <p className="activity">{feedLoading ? "" : "Activity"}</p>
      <div>
        {feedLoading ? (
          <Spinner color="#d9d9d9" />
        ) : (
          <>
            {feed.map((x, i) => {
              return (
                <Feed
                  key={i}
                  name={x.user_name}
                  profile={x.profile_pic}
                  description={x.feed_description}
                  image={x.focus_image}
                  date={dayjs(
                    new Date(dayjs.utc(x.feed_date).tz(timeZone))
                  ).fromNow()}
                  status={x.fav_status}
                  id={x.feed_id}
                  onFavourite={onFavourite}
                  favLoading={favLoading && x.feed_id === favLoading}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default Activity;
