import React, { useEffect, useState } from "react";
import ArrowLeftWhite from "../../../assets/images/icons/icn_arrow_left_white.png";
import ArrowRightWhite from "../../../assets/images/icons/icn_arrow_right_white.png";
import Focus from "../../../components/ui/Focus";
import axios from "../../../helper/api/api_instance";
import instance from "../../../helper/api/api_instance_node";
import toastr from "toastr";
import Spinner from "../../../components/ui/spinner";
import ExerciseImage from "../../../assets/images/core/Feed-img.png";
import FormModal from "../../../components/modals/FormModal";
import {
  Button,
  Calendar,
  Carousel,
  Col,
  Radio,
  Row,
  Select,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import {
  Base_Route,
  monthNames,
  focusColors,
  images,
} from "../../../helper/constant";
import Slider from "../../../components/ui/Slider";
import moment from "moment";
import ButtonData from "../../../components/ui/button";
const { Option } = Select;
const { Paragraph } = Typography;

const Dashboard = () => {
  const [achievementsList, setAchievementsList] = useState([]);
  const [achievementLoading, setAchievementLoading] = useState(false);
  const [workout, setWorkout] = useState([]);
  const [workoutLoading, setWorkoutLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [teamsLoading, setTeamsLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [curr, setCurr] = useState();
  const [currDate, setCurrDate] = useState(Date.now());
  const [pointsEarned, setPointsEarned] = useState(0);
  const [pointsEarnedLoading, setPointsEarnedLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const navigate = useNavigate();
  let count = -1;

  const array_chunks = (array, chunk_size) =>
    Array(Math.ceil(array.length / chunk_size))
      .fill()
      .map((_, index) => index * chunk_size)
      .map((begin) => array.slice(begin, begin + chunk_size));

  const chunks = array_chunks(achievementsList, 3);

  const onPanelChange = (value) => {
    setCurrDate(value.format("YYYY-MM-DD"));
  };

  const callHomeApi = (val) => {
    instance
      .get(`metaData/homeApi`)
      .then((res) => {
        if (res?.status === 200) {
          localStorage?.setItem('Resubscribe', false);
          if (
            res?.data?.data?.subscription?.is_subscribed === false &&
            res?.data?.data?.subscription?.can_un_subscribe === false &&
            res?.data?.data?.subscription?.expiry_date !== null
          ) {
            setDeleteModal(true);
            localStorage?.setItem('Resubscribe', true);
          } else if ( //old user flow
            res?.data?.data?.subscription?.is_subscribed === false &&
            res?.data?.data?.subscription?.can_un_subscribe === false &&
            res?.data?.data?.subscription?.expiry_date === null
          ) {
            navigate(`${Base_Route}/complete-profile`, {
              state: { skip_trial: res?.data?.data?.subscription?.skip_trial },
            });
          }
        }
      })
      .catch((err) => {
        toastr.error(err);
      });
  };

  const callPackages = () => {
    setLoading(true);
    instance
      .get(`/users/subscriptions/packages`)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data?.data?.packages[0]?.id) {
            createUserHandler();
          }
        }
      })
      .catch((error) => {
        toastr.error(error);
        setLoading(false);
      });
  };

  const createUserHandler = (id) => {
    instance
      .post(`users/subscriptions/createCustomerAndStartCheckout?id=${id}`)
      .then((res) => {
        if (res?.status === 200) {
          return (window.location.href = res?.data?.data?.redirect_url);
        }
      })
      .catch((error) => {
        toastr.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    callHomeApi();
    getAchivements();
    getWorkout();
    getTeams();
    getEarnpoints();
  }, []);

  const getEarnpoints = () => {
    setPointsEarnedLoading(true);
    axios
      .post(`user_earned_points`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setPointsEarned(res?.data?.data);
          setPointsEarnedLoading(false);
        } else {
          toastr.error(res.data.message);
          setPointsEarnedLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setPointsEarnedLoading(false);
      });
  };

  const getWorkout = () => {
    setWorkoutLoading(true);
    axios
      .post(`my_workout_list`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setWorkout(res?.data?.data?.assign_workout_data);
          setWorkoutLoading(false);
        } else {
          toastr.error(res.data.message);
          setWorkoutLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const getAchivements = () => {
    setAchievementLoading(true);
    axios
      .post(`achievements_list`)
      .then((res) => {
        setAchievementLoading(false);
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setAchievementsList(res?.data?.data);
        }
      })
      .catch((error) => {
        setAchievementLoading(false);
        toastr.error(error.message);
      });
  };
  const getTeams = () => {
    setTeamsLoading(true);
    axios
      .post(`athlete_profile`)
      .then((res) => {
        setTeamsLoading(false);
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setTeams(res?.data?.data?.social_data);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        setTeamsLoading(false);
        toastr.error(error.message);
      });
  };

  const monthOptions = [];

  for (let month = 1; month <= 12; month++) {
    monthOptions.push(
      <Option key={month} value={month}>
        {monthNames[month - 1]}
      </Option>
    );
  }

  const getNextWorkout = (val) => {
    if (val === "plus") {
      setCurr(true);
      setIndex((prevIndex) => prevIndex + 1);
      setCurr(!curr);
    } else {
      setCurr(false);
      setIndex((prevIndex) => prevIndex - 1);
      setCurr(!curr);
    }
  };

  const selectedTeamHandler = (id, title) => {
    navigate(`${Base_Route}/trufit/team`, {
      state: { group_id: id, focus_title: title },
    });
  };

  const handleLogout = () => {
    axios
      .post(`user_logout`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          localStorage.clear();
          navigate("/");
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  return (
    <>
      <div>
        <div className="content-athlete-dashboard">
          <div>
            <span className="coach-sm-heading">Welcome</span>
            <p className="coach-name-heading">
              {JSON.parse(
                localStorage.getItem("UserProfile")
              ).UserProfile.user_name?.toUpperCase()}
            </p>
          </div>

          <div
            className="dashboard-widgets-div"
            style={{ height: "140px !important" }}
          >
            <div className="widget d-flex flex-column justify-content-between">
              <p className="widget-heading">Workout Completed</p>
              <p className="widget-value">
                {pointsEarnedLoading ? (
                  <div className="d-flex justify-content-center mt-4">
                    <Spinner color="#d9d9d9" />
                  </div>
                ) : (
                  pointsEarned?.completed_workouts
                )}
              </p>
            </div>
            <div className="widget d-flex flex-column justify-content-between">
              <p className="widget-heading">Points Earned</p>
              <p className="widget-value ">
                {pointsEarnedLoading ? (
                  <div className="d-flex justify-content-center mt-4">
                    <Spinner color="#d9d9d9" />
                  </div>
                ) : (
                  pointsEarned?.total_points
                )}
              </p>
            </div>
            <div className="widget">
              <div className="d-flex justify-content-between align-items-center">
                <span className="widget-heading">Recent Achievements</span>
              </div>
              {achievementLoading ? (
                <div className="d-flex justify-content-center mt-4">
                  <Spinner color="#d9d9d9" />
                </div>
              ) : achievementsList?.length === 0 ? (
                <p className="tips-desc d-flex justify-content-center align-items-center h-100">
                  No Achievements yet
                </p>
              ) : (
                <Carousel
                  dotPosition="top"
                  autoplay
                  style={{
                    // height: "70px",
                    width: "100%",
                  }}
                >
                  {chunks &&
                    chunks?.map((chunk, i) => {
                      return (
                        <div key={i}>
                          <div className="d-flex justify-content-around mt-3">
                            {chunk?.map((x, j) => {
                              return (
                                <div key={j} className="text-center">
                                  <img
                                    alt="achivements_icon"
                                    src={x?.achievement_icon}
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      textAlign: "center !important",
                                      display: "inline",
                                    }}
                                  />
                                  <p className="achivement-text mt-1">
                                    {x?.achievement_name}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                </Carousel>
              )}
            </div>
          </div>
          <div className="workout-calendar-div mt-5">
            <div className="widget">
              <div className="d-flex align-items-center justify-content-between">
                <span className="workout-heading">Assigned Workouts</span>
                <div
                  className="d-flex justify-content-between"
                  style={{ width: "62px" }}
                >
                  <button
                    disabled={
                      index !== 0 && workout.length !== 0 ? false : true
                    }
                    className="arrow-div"
                    onClick={() => getNextWorkout("minus")}
                  >
                    <img
                      src={ArrowLeftWhite}
                      alt="arrow-white"
                      style={{ height: "17px" }}
                    />
                  </button>
                  <button
                    disabled={
                      index !== workout?.length - 1 && workout.length !== 0
                        ? false
                        : true
                    }
                    className="arrow-div"
                    onClick={() => getNextWorkout("plus")}
                  >
                    <img
                      src={ArrowRightWhite}
                      alt="arrow-white"
                      style={{ height: "17px" }}
                    />
                  </button>
                </div>
              </div>
              {workoutLoading ? (
                <div
                  style={{
                    marginTop: "25px",
                    height: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner color="#d9d9d9" />
                </div>
              ) : workout.length !== 0 ? (
                <div
                  className={
                    curr === true
                      ? "p-2 mt-4 rounded round-padding trans-div"
                      : curr === false
                      ? "p-2 mt-4 rounded round-padding trans-div-right"
                      : "p-2 mt-4 rounded round-padding"
                  }
                  style={{ backgroundColor: "#EEF8FE", cursor: "pointer" }}
                  onClick={() =>
                    navigate(`${Base_Route}/trufit/workout-detail`, {
                      state: { id: workout[index]?.workout_id },
                    })
                  }
                >
                  <p className="exercise-heading mt-2 mx-2">
                    {workout[index]?.workout_title}
                  </p>

                  <img
                    src={ExerciseImage}
                    alt="exercise"
                    className="rounded m-2"
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />

                  <Paragraph
                    className="exercise-desc mx-2"
                    ellipsis={{
                      rows: 2,
                      expandable: false,
                    }}
                  >
                    {workout[index]?.long_desc}
                  </Paragraph>
                </div>
              ) : (
                <div
                  className="mt-4"
                  style={{
                    width: "22.5vw",
                    height: "80%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p className="tips-desc " style={{ textAlign: "center" }}>
                    No Assign Workout
                  </p>
                </div>
              )}
            </div>
            <div className="widget">
              <Calendar
                fullscreen={false}
                onSelect={onPanelChange}
                disabledDate={(current) => {
                  return current && current > moment().endOf("day");
                }}
                mode="month"
                headerRender={({ value, type, onChange, onTypeChange }) => {
                  const start = 0;
                  const end = 12;
                  const monthOptions = [];
                  let current = value.clone();
                  const localeData = value.localeData();
                  const months = [];
                  for (let i = 0; i < 12; i++) {
                    current = current.month(i);
                    months.push(localeData.monthsShort(current));
                  }
                  for (let i = start; i < end; i++) {
                    monthOptions.push(
                      <Select.Option key={i} value={i} className="month-item">
                        {months[i]}
                      </Select.Option>
                    );
                  }
                  const year = value.year();
                  const month = value.month();
                  const options = [];
                  for (let i = year - 10; i < year + 10; i += 1) {
                    options.push(
                      <Select.Option key={i} value={i} className="year-item">
                        {i}
                      </Select.Option>
                    );
                  }
                  return (
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="workout-heading">Calendar</p>
                      <Select
                        className="select-year mb-2"
                        dropdownMatchSelectWidth={false}
                        value={month}
                        onChange={(newMonth) => {
                          const now = value.clone().month(newMonth);
                          onChange(now, type);
                        }}
                        style={{
                          width: "109px",
                          height: "40px",
                        }}
                      >
                        {monthOptions}
                      </Select>
                    </div>
                  );
                }}
              />
              <div className="mx-2 d-flex justify-content-end ">
                <Button
                  className="assign-btn"
                  onClick={() =>
                    navigate(`${Base_Route}/trufit/on-date-workout`, {
                      state: { myDate: dayjs(currDate).format("YYYY-MM-DD") },
                    })
                  }
                >
                  View Workout
                </Button>
              </div>
            </div>
          </div>
          <p className="notification-page-heading mt-5">Teams</p>
          {teamsLoading ? (
            <div
              style={{
                marginTop: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <Spinner color="#d9d9d9" />
            </div>
          ) : teams?.length !== 0 ? (
            <Slider>
              {teams?.map((x, i) => {
                if (count === focusColors.length - 1) {
                  count = 0;
                } else {
                  count++;
                }
                return (
                  <Focus
                    key={i}
                    title={x.group_name}
                    id={x.group_id}
                    image={images[count]}
                    color={focusColors[count]}
                    handleSelectFocus={selectedTeamHandler}
                  />
                );
              })}
            </Slider>
          ) : (
            <p className="tips-desc mt-4" style={{ textAlign: "center" }}>
              {" "}
              No Teams yet
            </p>
          )}
        </div>
      </div>
      <FormModal
        visible={deleteModal}
        title={"Want to Subscribe Again?"}
        // onCancel={() => setDeleteModal(false)}
        content={
          <div style={{ width: "370px" }}>
            <p className="sub-total">
              Your access to trufit is expired now, if you want to resume your
              subscription. click on the button below.
            </p>
            <ButtonData
              style={{ height: "45px" }}
              type="filled-blue-btn w-100"
              onClick={callPackages}
              loading={loading}
            >
              Subscribe Again
            </ButtonData>
            <ButtonData type="border-red-btn mt-2 w-100" onClick={handleLogout}>
              Logout
            </ButtonData>
          </div>
        }
      />
    </>
  );
};

export default Dashboard;
