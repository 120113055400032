import React from "react";
import { useEffect, useState } from "react";
import AthletePic from "../../assets/images/core/Athlete.png";
import ButtonData from "../../components/ui/button";
import axios from "../../helper/api/api_instance";
import Spinner from "../../components/ui/spinner";
import toastr from "toastr";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(relativeTime);

const timeZone = dayjs.tz.guess();
const Notifications = () => {
  const [notificationLoading, setNotificationLoading] = useState(false);

  const [notification, setNotification] = useState([]);
  useEffect(() => {
    getAllNotification();
  }, []);

  const getAllNotification = () => {
    setNotificationLoading(true);
    axios
      .post("notification_list")
      .then(async (res) => {
        setNotificationLoading(false);
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setNotification(res?.data?.data);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        setNotificationLoading(false);
        toastr.error(error.message);
      });
  };
  const changeRequestHandler = (req_id, group_id, req_name) => {
    const fd = new FormData();
    fd.append("request_user_id", req_id);
    fd.append("request_status", req_name);
    fd.append("group_id", group_id);
    axios
      .post("change_group_request", fd)
      .then(async (res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          toastr?.success(res?.data?.message);
          getAllNotification();
        } else {
          toastr.error(res.data.message);
          getAllNotification();
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };
  return (
    <div
      className={
        notificationLoading ? "activity-loader" : "activity-scroll-div"
      }
    >
      {notificationLoading ? (
        <Spinner color="#d9d9d9" />
      ) : (
        <>
          <div className="p-3">
            <p className="notification-page-heading mx-2">Notifications</p>
            {notification?.map((x, i) => {
              return (
                <div key={i} className="single-notification-on-page mx-2">
                  <div className="d-flex align-items-center">
                    <img
                      src={
                        x?.request_profile_pic
                          ? x?.request_profile_pic
                          : AthletePic
                      }
                      alt="profile-pic"
                      style={{
                        height: "50px",
                        width: "50px",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />
                    <div className="mx-2">
                      <span className="notification-description">
                        {x?.notification_message}
                      </span>
                    </div>
                  </div>

                  <div
                    className={`d-flex align-items-center ${
                      x?.request_status === "pending_request"
                        ? "justify-content-between"
                        : "justify-content-end"
                    }`}
                  >
                    {x?.request_status === "pending_request" && (
                      <div className="d-flex">
                        <ButtonData
                          type="assign-btn"
                          onClick={() =>
                            changeRequestHandler(
                              x?.request_user_id,
                              x?.group_id,
                              "accept_request"
                            )
                          }
                        >
                          Approve
                        </ButtonData>
                        <ButtonData
                          type="mx-2 join-btn"
                          onClick={() =>
                            changeRequestHandler(
                              x?.request_user_id,
                              x?.group_id,
                              "reject_request"
                            )
                          }
                        >
                          Dismiss
                        </ButtonData>
                      </div>
                    )}
                    <p className="notification-time-ago mt-3">
                      {" "}
                      {dayjs(
                        new Date(
                          dayjs.utc(x.notification_create_date).tz(timeZone)
                        )
                      ).fromNow()}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default Notifications;
