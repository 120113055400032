import React from "react";
import { Drawer } from "antd";
import logo from "../../assets/images/icons/img_logo_full.png";
import SiderMenu from "./components/SiderMenu";
import { useNavigate } from "react-router-dom";
import { Base_Route } from "../../helper/constant";
const DrawerSider = ({ open, setOpen, user_type }) => {
  const navigate = useNavigate();
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Drawer
        width={185}
        style={{
          borderRadius: "0px 20px 20px 0px",
          backgroundColor: "white",
        }}
        placement={"left"}
        closable={false}
        onClose={onClose}
        open={open}
        height={100}
      >
        <div className="drawer-logo">
          <img
            alt="logo"
            src={logo}
            onClick={() =>
              navigate(
                `${Base_Route}/trufit/${
                  user_type === "athlete"
                    ? "athlete-dashboard"
                    : "coach-dashboard"
                }`
              )
            }
            style={{ width: "75%", height: 43 }}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <SiderMenu setOpen={setOpen} current="drawer" />
        </div>
      </Drawer>
    </>
  );
};

export default DrawerSider;
