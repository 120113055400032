import React from "react";
import ModalCross from "../../assets/images/icons/icn_close.png";
import tick from "../../assets/images/icons/img_join_group_success.png";
import ButtonData from "../ui/button";
import { useNavigate } from "react-router-dom";

const SuccessModal = ({message, setVisible, type, modal_type, cancelTrial, oncancel}) => {
  const navigate = useNavigate()
  return (
    <>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <img src={tick} alt="" style={{ height: "50px", width: "50px" }} />
        <p className="mt-2 modal-title">{message}</p>
        {modal_type === 'stripe_subscription' && cancelTrial ? <ButtonData onClick={() => type === 'success' ? navigate('/trufit/athlete-dashboard') : setVisible(false) } type= { type === 'success' ? 'assign-btn' : 'filled-blue-btn'}>{type === 'success' ? "Go" : "Cancel" }</ButtonData> : modal_type === 'stripe_subscription'? <>
        <div className="d-flex">
          <ButtonData type='border-yellow-btn' onClick={() => oncancel()}>Ok</ButtonData>
          <ButtonData type='border-blue-btn mx-2' onClick={() => setVisible(false)} >Cancel</ButtonData>
        </div>
        </> : ''}
      </div>
    </>
  );
};

export default SuccessModal;
