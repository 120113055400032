import React, { useEffect, useState } from "react";
import AthletePic from "../../assets/images/core/Athlete.png";
import toastr from "toastr";
import axios from "../../helper/api/api_instance";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../components/ui/spinner";
import ProfileWorkout from "../../components/ui/ProfileWorkout";
import { Base_Route } from "../../helper/constant";

const CoachProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [workout, setWorkout] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id, profile, name, selected } = location.state;
  const getCoachData = (id) => {
    setLoading(true);
    const fd = new FormData();
    fd.append("login_user_id", id);
    axios
      .post(`find_workout_list`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setWorkout(res?.data?.data);
          setLoading(false);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const viewDetailHandler = (id) => {
    navigate(`${Base_Route}/trufit/workout-detail`, {
      state: { id },
    });
  };

  useEffect(() => {
    getCoachData(id);
    if (location.state.selected !== null) {
      localStorage.setItem(
        "Focus",
        JSON.stringify({
          group_id: selected, 
        })
      );
    }
  }, [id]);
  return (
    <>
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "80vh" }}
        >
          <Spinner color="#d9d9d9" />
        </div>
      ) : (
        <>
          <div className="m-4">
            <div className="d-flex align-items-center">
              <img
                src={profile ? profile : AthletePic}
                alt="athlete-pic"
                style={{ height: "50px", width: "50px", borderRadius: "50%" }}
              />
              <span className="mx-2 athlete-profile-name">{name}</span>
            </div>
            <p className="mt-4 athlete-profile-name">Created Workouts</p>
            <div className="row">
              {workout.map((x, i) => {
                return (
                  <div key={i} className="col-lg-4 ">
                    <ProfileWorkout
                      title={x.workout_title}
                      description={x.long_desc}
                      id={x.workout_id}
                      type = "workout_detail"
                      getWorkouts={viewDetailHandler}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CoachProfile;
