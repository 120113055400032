import React, { useEffect, useState } from "react";
import Exercise from "../../../components/ui/Exercise";
import toastr from "toastr";
import axios from "../../../helper/api/api_instance";
import Spinner from "../../../components/ui/spinner";
import FormModal from "../../../components/modals/FormModal";
import ButtonData from "../../../components/ui/button";
import AssignWorkoutModal from "../../../components/modals/AssignWorkoutModal";
import { useNavigate } from "react-router-dom";
import { Base_Route } from "../../../helper/constant";
import CreateWorkoutModal from "../../../components/modals/CreateWorkoutModal";
import LogWorkoutModal from "../../../components/modals/LogWorkoutModal";
import StripeModal from "../../../components/modals/StripeModal";

const Playlist = () => {
  const [playlist, setPlaylist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showAssign, setShowAssign] = useState(false);
  const [exercises, setExercises] = useState([]);
  const [workoutId, setWorkoutId] = useState("");
  const [btnName, setBtnName] = useState("");
  const [createObj, setCreateObj] = useState({});

  const navigate = useNavigate();
  let assignExercise = [];

  const user_type = JSON.parse(localStorage.getItem("UserProfile"))?.UserProfile
    ?.user_type;

  const getAllPlaylist = () => {
    setLoading(true);
    axios
      .post(`execise_playlist`)
      .then((res) => {
        setLoading(false);
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setPlaylist(res?.data?.data);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setLoading(false);
      });
  };

  const getExercises = (val) => {
    const fd = new FormData();
    fd.append("exercise_id", val);
    axios
      .post(`exercise_adjustment_list`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setExercises(res?.data?.data);

        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };
  useEffect(() => {
    getAllPlaylist();
  }, []);


  const onRemoveExercise = (e, id) => {
    e.stopPropagation();
    const fd = new FormData();
    fd.append("exercise_id", id);
    axios
      .post(`remove_execise_from_playlist`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setPlaylist(playlist.filter((x) => x.exercise_id !== id));
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const handleAssignWorkout = (name) => {
    setBtnName(name);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setShowAssign(false);
  };

  const onFinish = (values) => {
    if (btnName === "Save") {
      setCreateLoading(true);
      const fd = new FormData();
      fd.append("workout_title", values?.name);
      fd.append("long_desc", values?.description);
      fd.append("exercise_id", assignExercise.toString());
      fd.append("workout_status", "is_playlist");
      axios
        .post("create_workout", fd)
        .then(async (res) => {
          if (res?.status === 200 && res?.data?.responsecode === 1) {
            toastr?.success(res?.data?.message);
            setCreateLoading(false);
            setPlaylist([]);
            if (btnName !== "Save") {
              getExercises(assignExercise);
              setWorkoutId(res?.data?.data?.workout_id);
              setShowAssign(true);
            } else {
              setVisible(false);
            }
          } else {
            toastr.error(res.data.message);
            setCreateLoading(false);
          }
        })
        .catch((error) => {
          toastr.error(error.message);
          setCreateLoading(false);
        });
    } else {
      getExercises(assignExercise);
      setCreateObj(values);
      setShowAssign(true);
    }
  };
  return (
    <>
      <div className="p-4">
        <p className="athlete-profile-name">Playlist</p>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "80vh" }}
          >
            <Spinner color="#d9d9d9" />
          </div>
        ) : (
          <>
            <div className="row">
              {playlist?.length > 0 ? (
                playlist?.map((x, i) => {
                  assignExercise?.push(x?.exercise_id);
                  return (
                    <div key={i} className="col-lg-6">
                      <Exercise
                        id={x?.exercise_id}
                        image={x?.exercise_images[0]?.exercise_image}
                        name={x?.exercise_name}
                        description={x?.sort_description}
                        onFavourite={onRemoveExercise}
                        mode="playlist"
                      />
                    </div>
                  );
                })
              ) : (
                <p className="d-flex align-items-center justify-content-center view-more pt-3 pb-1 activity-loader">
                  <strong
                    className="yellow-underline"
                    onClick={() => navigate(`${Base_Route}/trufit/exercises`)}
                  >
                    Continue Browsing
                  </strong>{" "}
                  &nbsp;to add More Exercises to your Workout Playlist.
                </p>
              )}
            </div>
            {playlist?.length > 0 ? (
              <div>
                <p className="no-accout mt-4">
                  {" "}
                  <strong
                    className="yellow-underline"
                    onClick={() => navigate(`${Base_Route}/trufit/exercises`)}
                  >
                    Continue Browsing
                  </strong>{" "}
                  to add More Exercises to your Workout Playlist.
                </p>
                {user_type === "coach" ? (
                  <ButtonData
                    type="mt-4 assign-btn"
                    style={{ width: "12rem" }}
                    onClick={() => handleAssignWorkout("Assign")}
                  >
                    Assign Workout
                  </ButtonData>
                ) : (
                  <>
                    <div className="d-flex">
                      <ButtonData
                        type="mt-4 filled-blue-btn"
                        style={{ width: "12rem" }}
                        onClick={() => handleAssignWorkout("Save")}
                      >
                        Save Workout
                      </ButtonData>

                      <ButtonData
                        type="mt-4 mx-2 assign-btn"
                        style={{ width: "12rem" }}
                        onClick={() => handleAssignWorkout("Log")}
                      >
                        Log Workout
                      </ButtonData>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      <FormModal
        visible={visible}
        onCancel={handleCancel}
        content={
          showAssign && btnName === "Assign" ? (
            <AssignWorkoutModal
              workoutId={workoutId}
              handleCancel={handleCancel}
              exercises={exercises}
              setVisible={setVisible}
              workoutDetail={createObj}
              exercise_ids={assignExercise?.toString()}
              name={btnName}
            />
          ) : showAssign && btnName === "Log" ? (
            <LogWorkoutModal
              workoutId={workoutId}
              exercise_ids={assignExercise?.toString()}
              handleCancel={() => handleCancel()}
              exercises={exercises}
              workoutDetail={createObj}
              name={btnName}
              setVisible={setVisible}
              setWorkoutId={setWorkoutId}
              setPlaylist={setPlaylist}
            />
          ) : (
            <CreateWorkoutModal
              handleCancel={() => handleCancel()}
              name={btnName}
              onFinish={onFinish}
              loading={createLoading}
            />
          )
        }
      />
    </>
  );
};

export default Playlist;
