import React, { useEffect, useState } from "react";
import Inputfield from "../components/ui/Inputfield";
import CautionImg from "../assets/images/icons/icn_caution.png";
import LockImg from "../assets/images/icons/icn_lock_black.png";
import ButtonData from "../components/ui/button";
import { Card, Form } from "antd";
import toastr from "toastr";
import axios from "../helper/api/api_instance_node";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SuccessModal from "../components/modals/SuccessModal";
import FormModal from "../components/modals/FormModal";
import LogoutIcon from "../assets/images/icons/icn_logout.png";
import instance from "../helper/api/api_instance";
const CompleteProfile = () => {
  const [size, setSize] = useState("S");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [pack, setPackage] = useState({});
  const [packDetail, setPackageDetail] = useState({});
  const [skipTrial, setSkipTrial] = useState();
  const [input, setInput] = useState({
    postal_code: "",
    country: "",
    city: "",
  });
  const sizeArr = ["S", "M", "L", "XL", "XXL", "XXXL"];
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const Auth = JSON.parse(localStorage.getItem("UserProfile"));
  const { user_name, user_email_id } = Auth?.UserProfile;

  const onSizeChange = (val) => {
    setSize(val);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const callHomeApi = (val) => {
    axios
      .get(`metaData/homeApi`)
      .then((res) => {
        if (res?.status === 200) {
          setSkipTrial(res?.data?.data?.subscription?.skip_trial);
        }
      })
      .catch((err) => {
        toastr.error(err);
        setLoading(false);
      });
  };

  const callPackages = () => {
    axios
      .get(`/users/subscriptions/packages`)
      .then((res) => {
        if (res?.status === 200) {
          setPackageDetail(res?.data?.data);
          setPackage(res?.data?.data?.packages[0]);
        }
      })
      .catch((error) => {
        toastr.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      searchParams.get("k") === "success" ||
      searchParams.get("k") === "cancel"
    ) {
      setVisible(true);
    }
    callHomeApi();
    callPackages();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    if (size === "") {
      setLoading(false);
      toastr.error("Please Select the T-Shirt Size");
    } else {
      let obj = { ...values, ...input, tshirt_size: size };
      await axios
        .post(
          `users/subscriptions/createCustomerAndStartCheckout?id=${pack?.id}`,
          obj
        )
        .then((res) => {
          if (res?.status === 200) {
            return (window.location.href = res?.data?.data?.redirect_url);
          }
        })
        .catch((error) => {
          toastr.error(error);
          setLoading(false);
        });
    }
  };

  const onUserLogout = () => {
    // setLoading(true);
    instance
      .post(`user_logout`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          localStorage.clear();
          navigate("/");
        } else {
          toastr.error(res.data.message);
          // setLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        // setLoading(false);
      });
  };

  const handleZipCodeChange = (e) => {
    const inputValue = e.target.value;

    // Use a regular expression to allow only numeric values and hyphens
    const validZipCode = inputValue.replace(/[^0-9-]/g, "");

    setInput({ ...input, postal_code: validZipCode });
  };

  const handleCountryChange = (e) => {
    const inputValue = e.target.value;
    if (!/\d/.test(inputValue)) {
      setInput({ ...input, country: inputValue });
    }
  };

  const handleCityChange = (e) => {
    const inputValue = e.target.value;
    if (!/\d/.test(inputValue)) {
      setInput({ ...input, city: inputValue });
    }
  };
  return (
    <>
      <Card className="m-5 stripe-checkout-modal">
        <div className="m-4">
          <Form
            name="stripe-modal"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <div className="d-flex justify-content-between  align-items-center">
              <p className="athlete-profile-name">Complete Profile/Signup</p>
              <div className="round-white-div-lg mb-4">
                <img src={LogoutIcon} height={28} onClick={onUserLogout} />
              </div>
            </div>
            <div className="completed-profile-grid-area">
              <div>
                <div
                  style={{ border: "1px solid #E6E6E6", borderRadius: "10px" }}
                >
                  <div style={{ borderBottom: "1px solid #E6E6E6" }}>
                    <p className=" m-3 join-group-heading">User Details</p>
                  </div>
                  <div className="m-3">
                    <div className=" d-flex justify-content-between">
                      <p className="name-email-heading">Full Name:</p>
                      <p className="name-email-desc">{user_name}</p>
                    </div>
                    <div className=" d-flex justify-content-between">
                      <p className="name-email-heading">Email:</p>
                      <p className="name-email-desc">{user_email_id}</p>
                    </div>
                    <div className=" d-flex justify-content-between align-items-center">
                      <p className="col-lg-8 name-email-heading">
                        T-Shirt Size:
                        <strong className="shirt-desc">
                          (Information needed for promotional purposes)
                        </strong>
                      </p>
                      <div
                        className=" col-lg-4 d-flex justify-content-between"
                        style={{ height: "32px" }}
                      >
                        {sizeArr?.map((x, i) => {
                          return (
                            <div
                              key={i}
                              className="px-2 d-flex align-items-center"
                              style={{
                                border: "1px solid #E6E6E6",
                                cursor: "pointer",
                                borderRadius: "4px",
                                backgroundColor:
                                  size === x ? "#0081C6" : "white",
                                color: size === x ? "white" : "black",
                              }}
                              onClick={() => onSizeChange(x)}
                            >
                              {x}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="mt-4"
                  style={{ border: "1px solid #E6E6E6", borderRadius: "10px" }}
                >
                  <div style={{ borderBottom: "1px solid #E6E6E6" }}>
                    <p className=" m-3 join-group-heading">Billing Details</p>
                  </div>
                  <div className="m-2 mb-3 row gx-3 gy-3">
                    <div className="col-md-6">
                      <Form.Item
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your country name",
                          },
                        ]}
                      >
                        <Inputfield
                          value={input?.country}
                          onChange={handleCountryChange}
                          className="m-0 billing-input"
                          placeholder="Country *"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        name="line1"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your address line 1",
                          },
                        ]}
                      >
                        <Inputfield
                          className="m-0 billing-input"
                          placeholder="Address Line 1 *"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        name="line2"
                        rules={[
                          {
                            // required: true,
                            message: "Please enter your address line 2",
                          },
                        ]}
                      >
                        <Inputfield
                          className="m-0 billing-input"
                          placeholder="Address Line 2"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        name="city"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your city",
                          },
                        ]}
                      >
                        <Inputfield
                          value={input?.city}
                          onChange={handleCityChange}
                          className="m-0 billing-input"
                          placeholder="City *"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        name="state"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your state",
                          },
                        ]}
                      >
                        <Inputfield
                          className="m-0 billing-input"
                          placeholder="State/Province/Region *"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item
                        name="postal_code"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your postal code",
                          },
                        ]}
                      >
                        <Inputfield
                          value={input.postal_code}
                          onChange={handleZipCodeChange}
                          className="m-0 billing-input"
                          placeholder="Zip/ Postal Code *"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ backgroundColor: "#EEF8FE", borderRadius: "10px" }}>
                <div className="m-4">
                  <p className="join-group-heading">Purchase Summary</p>
                  <div
                    className="one-month-free d-flex justify-content-between"
                    style={{ borderBottom: "1px solid #000000" }}
                  >
                    <p>{pack?.name}</p>
                    <p style={{ color: "#15B24A" }}>
                      ${pack?.price?.toFixed(1)}
                    </p>
                  </div>
                  <div className="mt-2 sub-total d-flex justify-content-between">
                    <p>Subtotal</p>
                    <p>${pack?.price?.toFixed(1)}</p>
                  </div>
                  {/* <div className=" sub-total d-flex justify-content-between">
                  <p>Offer Discount</p>
                  <p>-$19.00</p>
                </div> */}
                  {/* <div className=" sub-total d-flex justify-content-between">
                  <p>Sales tax</p>
                  <p>Not yet calculated</p>
                </div> */}
                  <div
                    className="sub-total d-flex justify-content-between"
                    style={{ borderBottom: "1px solid #000000" }}
                  >
                    {/* <p>Est. Monthly Total</p>
                  <p>$19.00</p> */}
                  </div>
                  <div className=" mt-2 total-due d-flex justify-content-between">
                    <p>Est. Total due Today</p>
                    <p>
                      {skipTrial === false
                        ? "$0"
                        : `$${pack?.price?.toFixed(1)}`}
                    </p>
                  </div>
                  {skipTrial !== true && (
                    <div
                      style={{
                        backgroundColor: "#89C2DE",
                        border: "1px solid #0081C6",
                      }}
                    >
                      <div className="px-3 py-2 d-flex align-items-center">
                        <img src={CautionImg} height="15px" />
                        <p
                          className="caution-desc"
                          style={{ margin: "0px 10px" }}
                        >
                          You will not be charged until your trial ends in{" "}
                          {packDetail?.default_duration} days
                        </p>
                      </div>
                    </div>
                  )}

                  <Form.Item>
                    <ButtonData
                      loading={loading}
                      htmltype="submit"
                      type="addbutton mt-3"
                      style={{ width: "100%", margin: "0" }}
                    >
                      {skipTrial === false
                        ? "Start Free Trial"
                        : "Start Subscription"}
                    </ButtonData>
                  </Form.Item>
                  <div className="mt-2 d-flex align-items-baseline">
                    <img src={LockImg} height="15px" />
                    <p className="mx-2 mb-0 secure-checkout">
                      Secure checkout:
                    </p>
                  </div>
                  <p className="m-0 secure-checkout-desc">
                    For your convenience trufit will not store your payment
                    information.
                  </p>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Card>
      <FormModal
        visible={visible}
        onCancel={searchParams.get("k") === "cancel" && handleCancel}
        content={
          <SuccessModal
            message={
              searchParams.get("k") === "success"
                ? "Payment completed successfully"
                : "Payment was not completed"
            }
            setVisible={setVisible}
            type={searchParams.get("k")}
            cancelTrial={true}
            modal_type="stripe_subscription"
          />
        }
      />
    </>
  );
};

export default CompleteProfile;
