import React, { useEffect, useState } from "react";
import axios from "../../helper/api/api_instance";
import toastr from "toastr";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonData from "./button";
import Pill from "../ui/Pill";
import BackArrow from "../../assets/images/icons/icn_back.png";
import Heart from "../../assets/images/icons/icn_heart_unslctd.png";
import BlueHeart from "../../assets/images/icons/icn_heart_slctd.png";
import SuccessModal from "../modals/SuccessModal";
import FormModal from "../modals/FormModal";
import Spinner from "./spinner";
import { Base_Route } from "../../helper/constant";
const ExerciseDetail = ({ id, type, setExerciseCheck }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [favLoading, setFavLoading] = useState(false);
  const [fav, setFav] = useState({});
  const [visible, setVisible] = useState(false);
  const [playlistLoading, setPlaylistLoading] = useState(false);

  const { focus_id, focus_title } = location?.state;
  useEffect(() => {
    getExerciseDetail();
    if (location?.state?.focus_id !== undefined) {
      localStorage.setItem(
        "Exercise",
        JSON.stringify({
          focus_id,
          focus_title,
        })
      );
    }
  }, []);

  const getExerciseDetail = () => {
    setLoading(true);
    const fd = new FormData();
    fd.append("exercise_id", id);
    axios
      .post(`exercise_details`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setDetail(res?.data?.data);
          setFav(detail?.fav_status);
          setLoading(false);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const addToPlaylistHandler = () => {
    setPlaylistLoading(true);
    const fd = new FormData();
    fd.append("exercise_id", id);
    axios
      .post(`add_execise_to_playlist`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setVisible(true);
          setPlaylistLoading(false);
        } else {
          toastr.error(res.data.message);
          setPlaylistLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setPlaylistLoading(false);
      });
  };

  const onFavouriteHandler = async () => {
    setFavLoading(true);
    const fd = new FormData();
    fd.append("exercise_id", id);
    fd.append("is_favourite", detail?.fav_status === "true" ? "false" : "true");
    axios
      .post(`favourite_excrcise`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setFav(fav === "true" ? "false" : "true");
          setFavLoading(false);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setLoading(false);
        setFavLoading(false);
      });
  };

  const getVideoLink = () => {
    let link = null;
    if (detail != null) {
      if (detail?.exercise_videos?.[0]?.video_type === "is_video_link") {
        let linker = detail?.exercise_videos[0]?.video_url.split("/");
        link = "https://www.youtube.com/embed/" + linker[3];
      } else {
        link = detail?.exercise_videos?.[0]?.video_url;
      }
    }

    return link;
  };

  return (
    <>
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "80vh" }}
        >
          <Spinner color="#d9d9d9" />
        </div>
      ) : (
        <>
          <div className="mx-4">
            <div
              className="back-arrow"
              onClick={() =>
                type === "workout" ? setExerciseCheck(false) : navigate(-1)
              }
            >
              <img
                src={BackArrow}
                alt="black-arrow"
                style={{ height: "12px" }}
              />

              <p className="no-accout mx-2 mt-3">Back</p>
            </div>

            {getVideoLink() ? (
              <iframe
                src={getVideoLink()}
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="video"
                className="youtube-video"
                style={{
                  borderRadius: "25px",
                  objectFit: "cover",
                  top: "0",
                  bottom: "0",
                  left: "0",
                  right: "0",
                }}
              />
            ) : (
              <img
                src={
                  detail?.exercise_images
                    ? detail?.exercise_images[0]?.exercise_image
                    : ""
                }
                alt="exercise-detail"
                className="youtube-video"
                style={{
                  borderRadius: "25px",
                  objectFit: "cover",
                }}
              />
            )}

            <div
              className="mt-3"
              style={{
                display: "grid",
                gridTemplateColumns: "7fr 3fr",
                gridGap: "40px",
              }}
            >
              <div style={{ marginBottom: "20px" }}>
                <div className="d-flex ">
                  <p
                    style={{ fontFamily: "poppins-semibold", fontSize: "16px" }}
                  >
                    {detail?.exercise_name}
                  </p>

                  {favLoading ? (
                    <Spinner color="#d9d9d9" />
                  ) : (
                    <img
                      className="feed-heart-icon mx-2"
                      onClick={onFavouriteHandler}
                      src={fav === "true" ? BlueHeart : Heart}
                      alt="heart-icon"
                      style={{ height: "20px" }}
                    />
                  )}
                </div>
                <p className="exercise-desc">{detail?.sort_description}</p>
                <p className="exercise-heading mx-1 mt-3">Adjustments</p>
                <div className="d-flex flex-wrap">
                  {detail?.adjustment_list?.map((x, i) => {
                    return <Pill key={i} text={x.adjustments_name} />;
                  })}
                </div>
                <p className="exercise-heading mx-1 mt-3">Reps</p>
                <div className="d-flex flex-wrap">
                  {detail?.set_list?.map((x, i) => {
                    return <Pill key={i} text={x.set_name} />;
                  })}
                </div>

                <div
                  style={{ marginTop: "10px" }}
                  dangerouslySetInnerHTML={{ __html: detail?.long_description }}
                ></div>
                <ButtonData
                  type="mt-4 assign-btn"
                  style={{ width: "12rem" }}
                  onClick={addToPlaylistHandler}
                  loading={playlistLoading}
                >
                  Add to Playlist
                </ButtonData>
              </div>
              <div>
                <p className="exercise-heading mx-1">Filter by Focus</p>
                <div className="d-flex flex-wrap">
                  {detail?.data_fouce_name &&
                    detail?.data_fouce_name?.split(",")?.map((x, i) => {
                      return <Pill key={i} text={x} />;
                    })}
                </div>
                <p className="exercise-heading mx-1 mt-3">Ability</p>
                <div className="d-flex flex-wrap">
                  {detail?.data_disability_name &&
                    detail?.data_disability_name?.split(",")?.map((x, i) => {
                      return <Pill key={i} text={x} />;
                    })}
                </div>

                <p className="exercise-heading mx-1 mt-3">Equipment</p>
                <div className="d-flex flex-wrap">
                  {detail?.data_equipment_name &&
                    detail?.data_equipment_name?.split(",")?.map((x, i) => {
                      return <Pill key={i} text={x} />;
                    })}
                </div>
              </div>
            </div>
          </div>

          <FormModal
            visible={visible}
            onCancel={() => setVisible(false)}
            content={
              <SuccessModal
                message="Exercise added to playlist"
                setVisible={setVisible}
              />
            }
          />
        </>
      )}
    </>
  );
};

export default ExerciseDetail;
