import React from "react";
import Heart from "../../assets/images/icons/icn_heart_unslctd.png";
import FavouriteHeart from "../../assets/images/icons/icn_heart_slctd.png";
import AthletePic from "../../assets/images/core/Athlete.png";
import FeedImage from "../../assets/images/core/Feed-img.png";
import Spinner from "./spinner";
const Feed = ({
  description,
  name,
  profile,
  date,
  status,
  id,
  onFavourite,
  favLoading,
}) => {
  return (
    <div className="feed-div mt-3">
      <div
        className=" d-flex justify-content-between"
        style={{ padding: "25px 25px 0px" }}
      >
        <div className=" d-flex">
          <img
            src={profile ? profile : AthletePic}
            alt="athlete-pic"
            style={{
              height: "50px",
              width: "50px",
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
          <div className="mx-2">
            <span className="athlete-name">{name}</span>
            <p className="timer">{date}</p>
          </div>
        </div>

        <div onClick={() => onFavourite(id, status)}>
          {favLoading ? (
            <Spinner color="#d9d9d9" />
          ) : (
            <img
              className="feed-heart-icon"
              src={status === "true" ? FavouriteHeart : Heart}
              alt="heart-icon"
              style={{ height: "20px" }}
            />
          )}
        </div>
      </div>
      <p className="feed-desc mt-3" style={{ padding: "0px 25px" }}>
        {description}
      </p>
      <img
        src={FeedImage}
        alt="feed-pic"
        style={{ width: "100%", marginBottom: "20px" }}
      />
    </div>
  );
};

export default Feed;
