import React, { useEffect, useState } from "react";
import Spinner from "./spinner";
import toastr from "toastr";
import axios from "../../helper/api/api_instance";
import ModalCross from "../../assets/images/icons/icn_close.png";

const Disabilities = ({ visible, id, cancel }) => {
  const [ability, setAbility] = useState([]);
  const [loading, setLoading] = useState(false);

  const callApi = () => {
    if (visible) {
      setLoading(true);
      const fd = new FormData();
      fd.append("user_id", id);
      axios
        .post(`other_user_ability`, fd)
        .then((res) => {
          if (res?.status === 200 && res?.data?.responsecode === 1) {
            setAbility(res?.data?.data);
            setLoading(false);
          } else {
            toastr.error(res.data.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          toastr.error(error.message);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    callApi();
  }, []);
  return (
    <>
      {" "}
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner color="#d9d9d9" />
        </div>
      ) : (
        <div className="mt-4">
          <img
            src={ModalCross}
            alt="modal-cross"
            className="modal-cross"
            onClick={() => cancel()}
          />
          {ability?.map((x, i) => {
            return (
              <div key={i} className="disabilities-div">
                <p className="m-0 disabilities-text">{x.disability_name}</p>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Disabilities;
