import React from "react";
import groupProfile from "../../assets/images/core/Athlete.png";
const GroupCard = ({
  selected,
  onSelectGroup,
  title,
  description,
  status,
  id,
  handleEditGroup,
  group,
}) => {
  return (
    <>
      <div
        className="group-card ms-3"
        onClick={() => onSelectGroup(id)}
        style={{
          border: selected === id ? "1px solid #0081C6" : "none",
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <img
            src={group?.group_image ? group?.group_image : groupProfile}
            height={50}
            width={50}
            style={{ borderRadius: "50%" }}
          />
          <p
            className="group-edit"
            onClick={(event) => handleEditGroup(event, group)}
          >
            Edit
          </p>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <p className="group-heading">{title}</p>
          <p className="created">
            {status === "is_not_admin" ? "Created" : ""}
          </p>
        </div>
        <p className="exercise-desc">{description}</p>
      </div>
    </>
  );
};

export default GroupCard;
