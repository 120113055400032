import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import toastr from "toastr";
import EmailLogo from "../../../assets/images/icons/icn_email.png";
import EmailLock from "../../../assets/images/icons/icn_lock.png";
import Logo from "../../../assets/images/icons/img_logo_full.png";
import Inputfield from "../../../components/ui/Inputfield";
import PasswordInput from "../../../components/ui/passwordinput";
import ButtonData from "../../../components/ui/button";
import AuthFrame from "../../../components/ui/AuthFrame";
import axios from "../../../helper/api/api_instance";
import instance from "../../../helper/api/api_instance_node";
import { Base_Route } from "../../../helper/constant";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    try {
      const user_type = JSON.parse(localStorage.getItem("UserProfile"))
        ?.UserProfile?.user_type;
      const authUser = JSON.parse(localStorage.getItem("AuthUser"));
      if (authUser && user_type === "coach") {
        navigate(`${Base_Route}/trufit/coach-dashboard`);
      } else if (authUser && user_type === "athlete") {
        // navigate(`${Base_Route}/trufit/athlete-dashboard`);
        callHomeApi();
      }
    } catch (error) {}
    document.body.classList.add("auth-body-bg");
    return () => {
      document.body.classList.remove("auth-body-bg");
    };
  }, []);

  const callHomeApi = (val) => {
    instance
      .get(`metaData/homeApi`)
      .then((res) => {
        if (res?.status === 200) {
          localStorage?.setItem(
            "is_manual_subscribe",
            res?.data?.data?.subscription?.is_manual_subscribe
          );
          if (
            (res?.data?.data?.subscription?.is_subscribed === false &&
              res?.data?.data?.subscription?.hasOwnProperty(
                "can_un_subscribe"
              )) ||
            (res?.data?.data?.subscription?.is_subscribed === false &&
              res?.data?.data?.subscription?.can_un_subscribe === false &&
              res?.data?.data?.subscription?.expiry_date === null)
          ) {
            navigate(`${Base_Route}/complete-profile`, {
              state: { skip_trial: res?.data?.data?.subscription?.skip_trial },
            });
          } else {
            navigate(`${Base_Route}/trufit/athlete-dashboard`);
          }
        }
      })
      .catch((err) => {
        toastr.error(err);
        setLoading(false);
      });
  };
  const MyComp = () => {
    const onFinish = (values) => {
      setLoading(true);
      const fd = new FormData();
      fd.append("user_email_id", values?.email);
      fd.append("user_password", values?.password);
      axios
        .post(`user_login`, fd)
        .then((res) => {
          if (res?.status === 200 && res?.data?.responsecode === 1) {
            // setLoading(false);
            localStorage.setItem(
              "AuthUser",
              JSON.stringify({
                UserId: res.data.data.user_id,
                Token: res.data.data.Token,
              })
            );
            localStorage.setItem(
              "UserProfile",
              JSON.stringify({
                UserProfile: res.data.data,
              })
            );
            if (res?.data?.data?.user_type === "coach") {
              navigate(`${Base_Route}/trufit/coach-dashboard`);
            } else {
              callHomeApi(res.data.data);
              // navigate(`${Base_Route}/trufit/athlete-dashboard`);
            }
          } else {
            toastr.error(res.data.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    };

    return (
      <>
        <img src={Logo} alt="trufit-logo" className="auth-logo" />
        <p className="login-des mx-4 mt-2">
          Log in to your exising account of TruFit
        </p>
        <div style={{ marginTop: "30px" }}>
          <Form
            name="login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid email",
                  validator: (_, value) => {
                    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Please input your E-mail!");
                    }
                  },
                },
              ]}
            >
              <Inputfield
                prefix={
                  <img alt="" src={EmailLogo} style={{ height: "20px" }} />
                }
                placeholder="Enter Email"
                className="login-input"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
                { min: 8, message: "Password must be of minimum 8 characters" },
              ]}
            >
              <PasswordInput
                prefix={
                  <img alt="" src={EmailLock} style={{ height: "20px" }} />
                }
                placeholder="Enter Password"
                className="login-input mt-3"
              />
            </Form.Item>
            <p
              className="forgot-pas-desc mt-2 "
              onClick={() => navigate(`${Base_Route}/forgot`)}
            >
              Forgot Password?
            </p>

            <ButtonData
              htmltype="submit"
              type="authButton mt-3"
              loading={loading}
            >
              Login
            </ButtonData>
            <p className="no-accout mt-2">
              Don’t have an account?{" "}
              <strong
                onClick={() => navigate(`${Base_Route}/signup`)}
                className="yellow-underline"
              >
                Sign Up
              </strong>
            </p>
          </Form>
        </div>
      </>
    );
  };
  return (
    <>
      <AuthFrame fields={<MyComp />} />
    </>
  );
};

export default Login;
