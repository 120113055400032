import { Form, Switch, Upload } from "antd";
import profileDp from "../../../../assets/images/core/dummy-img.png";
import ButtonData from "../../../../components/ui/button";
import BlueCamera from "../../../../assets/images/icons/icn_camera_blue.png";
import Inputfield from "../../../../components/ui/Inputfield";
import User from "../../../../assets/images/icons/icn_profile.png";

const ProfileDetail = ({onFinish, state, form, props, fileList, abilities, UserProfile, onChange, loading, setDeleteModal}) => {
  return (
    <>
      <Form
        name="edit-user"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        form={form}
        autoComplete="off"
      >
        <div className="row gx-5 gy-5 mt-4">
          <div className="col-lg-5 d-flex align-items-center justify-content-center">
            <div className="profile-main-div d-flex flex-column align-items-center justify-content-center p-4">
              <img
                src={state?.imageUrl ? state?.imageUrl : profileDp}
                alt="profile"
                width="220px"
                height="220px"
                style={{ borderRadius: "50%" }}
              />
              <div
                className="d-flex justify-content-center mt-3"
                style={{ width: "60%" }}
              >
                <Upload
                  {...props}
                  fileList={fileList}
                  accept="image/png, image/jpeg"
                  maxCount={1}
                >
                  <ButtonData
                    type="border-blue-btn profile-btn"
                    icon={
                      <img
                        src={BlueCamera}
                        alt="blue-camera"
                        style={{ height: "20px" }}
                      />
                    }
                  >
                    Upload
                  </ButtonData>
                </Upload>
              </div>
              <div className="edit-profile-input-div">
                <Form.Item
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter user name",
                    },
                  ]}
                >
                  <Inputfield
                    prefix={
                      <img alt="" src={User} style={{ height: "20px" }} />
                    }
                    placeholder="Enter Name"
                    className="edit-profile-input mt-4"
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="profile-disabilities-div d-flex flex-column p-4">
              {abilities?.map((x, i) => {
                return (
                  <div
                    key={i}
                    className="d-flex justify-content-between align-items-center mt-2"
                    style={{
                      borderBottom: "1px solid rgba(0, 124, 186, 0.15)",
                    }}
                  >
                    <p className="faq-sub-heading m-2">{x?.disability_name}</p>
                    <Switch
                      checkedChildren="✓"
                      unCheckedChildren="+"
                      defaultChecked={UserProfile?.disability_id.includes(
                        x?.disability_id
                      )}
                      onChange={(value) => onChange(value, x?.disability_id)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="d-flex w-50 mt-4 mb-4">
          <ButtonData
            type="assign-btn assign-text mt-3 w-50 px-3"
            style={{ height: "45px" }}
            htmltype="submit"
            loading={loading}
          >
            Save
          </ButtonData>

          <ButtonData
            onClick={() => setDeleteModal(true)}
            type="border-red-btn mt-3 w-50 px-3 mx-3"
          >
            Delete Account
          </ButtonData>
        </div>
      </Form>
    </>
  );
};

export default ProfileDetail;
