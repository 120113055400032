import React, { useEffect, useState } from "react";
import ModalCross from "../../assets/images/icons/icn_close.png";
import { Form, Radio } from "antd";
import Inputfield from "./Inputfield";
import { Input, Upload } from "antd";
import toastr from "toastr";
import Copy from "../../assets/images/icons/icn_copy.png";
import Camera from "../../assets/images/icons/icn_camera_blue.png";
import ButtonData from "./button";
import CopyToClipboard from "react-copy-to-clipboard";
const CreateGroup = ({
  handleCancel,
  onFinish,
  onChange,
  value,
  invite,
  loading,
  fileList,
  setFileList,
  state,
  setState,
  edit,
  editGroup,
}) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();

  useEffect(() => {
    if (edit) {
      form.setFieldsValue({
        name: editGroup?.group_name,
        groupDescription: editGroup?.group_description,
      });
      setState({imageUrl: editGroup?.group_image })
    }
  }, []);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    getBase64(newFileList.slice(-1)[0]?.originFileObj, (imageUrl) =>
      setState({
        imageUrl,
      })
    );
  };

  const props = {
    action: "",
    onChange: handleChange,
    multiple: false,
  };
  return (
    <div className="mt-3">
      <img
        src={ModalCross}
        alt="modal-cross"
        className="modal-cross"
        onClick={() => handleCancel()}
      />
      <p className="m-0 group-type">Group Type</p>
      <Form
        name="modal-form"
        initialValues={{
          remember: true,
        }}
        form={form}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="radioGroup"
          rules={[
            {
              required: false,
              message: "Please select group type",
            },
          ]}
        >
          <Radio.Group
            className=" mt-2"
            onChange={onChange}
            value={value}
            defaultValue={value}
            disabled={edit}
          >
            <Radio
              value={2}
              style={{ color: value === 2 ? "#007CBA" : "#526B78" }}
              className="group-type"
            >
              Public Group
            </Radio>
            <Radio
              value={1}
              style={{ color: value === 1 ? "#007CBA" : "#526B78" }}
              className="group-type"
            >
              Private Group
            </Radio>
          </Radio.Group>
        </Form.Item>
        <div className="d-flex align-items-center">
          {state?.imageUrl ? (
            <img
              src={state?.imageUrl}
              height="85"
              width="85"
              className="mt-2"
              style={{ borderRadius: "50%" }}
            />
          ) : (
            <div
              className="d-flex align-items-center justify-content-center mt-2"
              style={{
                height: "85px",
                width: "85px",
                backgroundColor: "#F9F9F9",
                border: "2px solid #526B780F",
                borderRadius: "50%",
              }}
            >
              <img src={Camera} height="25" />
            </div>
          )}

          <Upload
            {...props}
            fileList={fileList}
            accept="image/png, image/jpeg"
            maxCount={1}
          >
            <ButtonData type="mx-3 modal-btn" style={{ height: "45px" , width:'150px'}}>
              Upload
            </ButtonData>
          </Upload>
        </div>

        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter group name",
            },
          ]}
        >
          <Inputfield
            placeholder="Enter Group Name"
            className="modal-field mt-3"
          />
        </Form.Item>
        {value === 1 && (
          <Inputfield
            placeholder="Enter Invite Code"
            className="modal-field mt-3"
            style={{ caretColor: "transparent" }}
            value={invite}
            suffix={
              <CopyToClipboard
                text={invite}
                onCopy={() => toastr.success("Invite code copied")}
              >
                <img
                  className="feed-heart-icon"
                  alt=""
                  src={Copy}
                  style={{ height: "20px" }}
                />
              </CopyToClipboard>
            }
          />
        )}

        <Form.Item
          name="groupDescription"
          rules={[
            {
              required: true,
              message: "Please enter group description",
            },
          ]}
        >
          <TextArea
            className="mt-3 modal-field"
            rows={4}
            placeholder="Group Description"
            maxLength={300}
            value={editGroup?.group_description}
          />
        </Form.Item>
        <Form.Item name="groupBtn">
          <ButtonData loading={loading} htmltype="submit" type="modal-btn mt-3">
            {`${edit ? "Update" : "Create"} Group`}
          </ButtonData>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateGroup;
