import React, { useState } from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import Header from "../../components/ui/header";
import SiderMenu from "../../components/sider/components/SiderMenu";
import Activity from "../../components/ui/Activity";
const { Content, Sider } = Layout;
const AppLayout = ({ is_feed }) => {
  const [collapsed, setCollapsed] = useState(false);


  return (
    <>
      <Layout style={{ height: "100vh" }}>
        <Header collapsed={collapsed} setCollapsed={setCollapsed} />
        <Layout>
          <Sider
            style={{
              paddingInline: "0px",
              backgroundColor: "#FFFFFF",
            }}
            trigger={null}
            collapsible
            collapsed={collapsed}
          >
            <SiderMenu />
          </Sider>
          <Layout>
            {is_feed ? (
              <Content
              className="activity-scroll-div"
              style={{
                margin: 0,
                backgroundColor: "#FFFFFF",
              }}
            >
             <div className="dashboard-main-div">
               <div className="activity-scroll-div">
                    <Outlet/>
                </div>
               <div className="activity-feed-div">
                   <Activity />
              </div>
             </div>
            </Content>
            ) :(
            <Content
              className="activity-scroll-div"
              style={{
                margin: 0,
                backgroundColor: "#FFFFFF",
              }}
            >
              <Outlet />
            </Content>
              )}
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default AppLayout;
