import React, { useState } from "react";
import AuthFrame from "../../../components/ui/AuthFrame";
import ButtonData from "../../../components/ui/button";
import PasswordInput from "../../../components/ui/passwordinput";
import EmailLock from "../../../assets/images/icons/icn_lock.png";
import axios from "../../../helper/api/api_instance";
import { useLocation, useNavigate } from "react-router-dom";
import toastr from "toastr";
import { Form } from "antd";
import { Base_Route } from "../../../helper/constant";

const NewPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const MyComp = () => {
    const [loading, setLoading] = useState(false);
    const onFinish = (values) => {
      setLoading(true);
      const fd = new FormData();
      fd.append("user_email_id", location?.state?.email);
      fd.append("new_password", values?.newPassword);
      fd.append("confirm_new_password", values?.confirmPassword);
      axios
        .post(`change_password`, fd)
        .then((res) => {
          if (res?.status === 200 && res?.data?.responsecode === 1) {
            toastr.success("Password reset successfully");
            setLoading(false);
            navigate('/');
          } else {
            setLoading(false);
            toastr.error(res.data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toastr.error(error.message);
        });
    };
    return (
      <>
        <p className="forgot-heading mt-4 mb-0">Create New Password</p>
        <p className="login-des mx-4 mt-2" style={{ color: "#0A364B" }}>
          Your new password must be different from Previous password.
        </p>
        <div className="mt-4">
          <Form
            name="login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
                {
                  min: 8,
                  message: "Please enter minimum 8 character password",
                },
              ]}
            >
              <PasswordInput
                prefix={
                  <img alt="" src={EmailLock} style={{ height: "20px" }} />
                }
                placeholder="Enter Password"
                className="login-input mt-3"
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              dependencies={["newPassword"]}
              rules={[
                {
                  required: true,
                  message: "Please enter confirm password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Password and confirm password must be same.")
                    );
                  },
                }),
              ]}
            >
              <PasswordInput
                prefix={
                  <img alt="" src={EmailLock} style={{ height: "20px" }} />
                }
                placeholder="Confirm Password"
                className="login-input mt-3"
              />
            </Form.Item>
            <ButtonData
              htmltype="submit"
              type="authButton mt-4"
              loading={loading}
            >
              Reset Password
            </ButtonData>
          </Form>
        </div>
      </>
    );
  };
  return (
    <>
      <AuthFrame fields={<MyComp />} />
    </>
  );
};

export default NewPassword;
