import React, { useState } from "react";
import AuthFrame from "../../../components/ui/AuthFrame";
import OtpLogo from "../../../assets/images/icons/img_otp.png";
import OTPInput from "otp-input-react";
import ButtonData from "../../../components/ui/button";
import axios from "../../../helper/api/api_instance";
import { useLocation, useNavigate } from "react-router-dom";
import toastr from "toastr";
import { Base_Route } from "../../../helper/constant";
const Otp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const MyComp = () => {
    const [loading, setLoading] = useState(false);
    const [OTP, setOTP] = useState("");

    const reSendOtp = () => {
      setLoading(true);
      const fd = new FormData();
      fd.append("user_email_id", location?.state?.email);
      axios
        .post(`forgot_password`, fd)
        .then((res) => {
          if (res?.status === 200 && res?.data?.responsecode === 1) {
            toastr.success("OTP request sent");
            setLoading(false);
          } else {
            toastr.error(res.data.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          toastr.error(error.message);
          setLoading(false);
        });
    };

    const onSubmit = () => {
      if (OTP !== "") {
        setLoading(true);
        const fd = new FormData();
        fd.append("user_email_id", location?.state?.email);
        fd.append("verify_code", OTP);
        axios
          .post(`verify_code`, fd)
          .then((res) => {
            if (res?.status === 200 && res?.data?.responsecode === 1) {
              setLoading(false);
              navigate(`${Base_Route}/new-password`, {
                state: { email: location?.state?.email },
              });
            } else {
              toastr.error(res.data.message);
              setLoading(false);
            }
          })
          .catch((error) => {
            toastr.error(error.message);
            setLoading(false);
          });
      }
    };
    return (
      <>
        <img src={OtpLogo} alt="forgot-logo" style={{ height: "150px" }} />
        <p className="forgot-heading mt-4 mb-0">Verification</p>
        <p className="login-des mx-4 mt-2" style={{ color: "#0A364B" }}>
          Enter the Verification code we just sent on your email address.
        </p>
        <div className="mt-4">
          <OTPInput
            className="otp-input"
            value={OTP}
            onChange={setOTP}
            autoFocus
            OTPLength={4}
            otpType="number"
            disabled={false}
          />

          <ButtonData
            type="authButton mt-4"
            onClick={() => onSubmit()}
            loading={loading}
          >
            Verify
          </ButtonData>
          {!loading && (
            <p className="no-accout mt-2">
              Haven’t received OTP?&nbsp;
              <strong className="yellow-underline" onClick={() => reSendOtp()}>
                Resend OTP
              </strong>
            </p>
          )}
        </div>
      </>
    );
  };
  return (
    <>
      <AuthFrame fields={<MyComp />} />
    </>
  );
};

export default Otp;
