import { Button } from "antd";
import React from "react";
import { ThreeDots } from "react-loader-spinner";

const ButtonData = ({
  type,
  htmltype,
  onClick,
  children,
  loading,
  style,
  icon,
  disabled,
}) => {
  const myClass = `button ${type}`;
  return (
    <Button
      style={style}
      htmlType={htmltype}
      className={myClass}
      onClick={onClick}
      icon={icon}
      disabled={disabled}
    >
      {loading ? (
        <ThreeDots
          height="30"
          width="30"
          radius="9"
          color="#d9d9d9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName="d-flex align-items-center"
          visible={true}
        />
      ) : (
        children
      )}
    </Button>
  );
};

export default ButtonData;
