import React from "react";
import Heart from "../../assets/images/icons/icn_heart_unslctd.png";
import FavouriteHeart from "../../assets/images/icons/icn_heart_slctd.png";
import Spinner from "./spinner";
import { Typography } from "antd";
const { Paragraph } = Typography;

const WorkoutCard = ({
  type,
  name,
  description,
  id,
  selected,
  onSelectWorkout,
  current,
  thumb,
  favLoading,
  status,
  onFavourite,
}) => {
  return (
    <div
      onClick={() => onSelectWorkout(id, current)}
      className="p-2 mt-4 rounded"
      style={{
        backgroundColor: "#EEF8FE",
        cursor: "pointer",
        border: selected === id ? "2px solid #0181C6" : "none",
      }}
    >
      <div className="d-flex justify-content-between mx-2 mt-2">
        <div className="d-flex align-items-center" style={{ flexWrap: "wrap" }}>
          <p className="exercise-heading ">{name}</p>
          {type !== "createdByCoach" && (
            <p className="live-broadcast mx-2">. Live Broadcast</p>
          )}
        </div>
        <div onClick={(e) => onFavourite(e, id, status)}>
          {favLoading ? (
            <Spinner color="#d9d9d9" />
          ) : (
            <img
              className="feed-heart-icon"
              src={status === "true" ? FavouriteHeart : Heart}
              alt="heart-icon"
              style={{ height: "20px" }}
            />
          )}
        </div>
      </div>
      {type !== "createdByCoach" && (
        <img
          src={thumb}
          alt="exercise"
          style={{
            width: "100%",
            height: "140px",
            paddingInline: "10px",
            borderRadius: "12px",
            objectFit: "cover",
          }}
        />
      )}
      <Paragraph
        className="exercise-desc-2 mx-2"
        ellipsis={{
          rows: 4,
          expandable: false,
        }}
      >
        {description}
      </Paragraph>
    </div>
  );
};

export default WorkoutCard;
