import React from "react";

const Focus = ({
  title,
  id,
  image,
  color,
  borderColor,
  type,
  handleSelectFocus,
  selected,
  focus,
}) => {
  return (
    <div
      className="focus-item d-flex flex-column align-items-center justify-content-around"
      style={{
        backgroundColor: color,
        border: `${selected === id ? "3px" : "0px"} solid ${borderColor}`,
      }}
      onClick={() => (!focus ? handleSelectFocus(id, title) : null)}
    >
      <img
        src={image}
        alt="focus-img"
        style={{
          height: "150px",
          width: "150px",
          padding: "10px",
          objectFit: "contain",
        }}
      />
      <div className="scroll-card-heading ellipsis-string">{title}</div>
    </div>
  );
};

export default Focus;
