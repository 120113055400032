import React from "react";
import exerciseImg from "../../assets/images/core/Feed-img.png";
import BlueLeftArrow from "../../assets/images/icons/icn_arrow_right_blue.png";
import ButtonData from "./button";
import Heart from "../../assets/images/icons/icn_heart_unslctd.png";
import BlueHeart from "../../assets/images/icons/icn_heart_slctd.png";
import ModalCross from "../../assets/images/icons/icn_close.png";
import { useNavigate } from "react-router";
import Spinner from "./spinner";
import { Base_Route } from "../../helper/constant";

const Exercise = ({
  id,
  image,
  name,
  description,
  mode,
  status,
  onFavourite,
  loading,
  onAddPlaylist,
  playlistLoading,
  selected,
  pageName,
  focus_id,
  focus_title,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        onClick={() =>
          navigate(`${Base_Route}/trufit/exercise-detail`, {
            state: { id, focus_id, focus_title },
          })
        }
        className={
          pageName === "athlete-profile"
            ? "workout-exercise-card-in-athelete-profile mt-4"
            : "workout-exercise-card mt-4"
        }
      >
        <img
          alt="exercise-images"
          src={image ? image : exerciseImg}
          style={{
            height: "160px",
            width: "100%",
            // objectFit: "none",
            borderRadius: "10px",
          }}
        />

        <div
          style={{ padding: "10px 10px 0px 15px", width: "100%" }}
          className="d-flex flex-column justify-content-between"
        >
          <div>
            <div className="d-flex justify-content-between">
              <p className="exercise-heading">{name}</p>
              {mode === "exercises" && (
                <div onClick={(e) => onFavourite(e, id, status)}>
                  {loading ? (
                    <Spinner color="#d9d9d9" />
                  ) : (
                    <img
                      className="feed-heart-icon"
                      src={status === "true" ? BlueHeart : Heart}
                      alt="heart-icon"
                      style={{ height: "20px" }}
                    />
                  )}
                </div>
              )}

              {mode === "playlist" && (
                <img
                  src={ModalCross}
                  alt="modal-cross"
                  style={{ height: "25px", width: "25px" }}
                  onClick={(e) => onFavourite(e, id)}
                />
              )}
            </div>
            <p
              className={
                mode === "exercises"
                  ? "exercise-desc"
                  : "exercise-desc-playlist"
              }
            >
              {description}
            </p>
          </div>
          {mode === "exercises" && (
            <div className="d-flex justify-content-between">
              <ButtonData
                type="border-blue-btn"
                style={{ borderRadius: "10px", width: "52%" }}
                onClick={() =>
                  navigate(`${Base_Route}/trufit/exercise-detail`, {
                    state: { id },
                  })
                }
              >
                Learn More
              </ButtonData>
              <ButtonData
                type="assign-btn"
                loading={id === selected ? playlistLoading : ""}
                onClick={(e) => onAddPlaylist(e, id)}
                style={{ borderRadius: "10px", width: "54%", marginLeft: "2%" }}
              >
                Add to Playlist
              </ButtonData>
            </div>
          )}
        </div>
        {!mode && (
          <div className="d-flex align-items-center justify-content-start">
            <img
              alt="blueLeftArrow-icon"
              src={BlueLeftArrow}
              style={{
                height: "20px",
                cursor: "pointer",
                backgroundColor: "green !important",
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Exercise;
