import React, { useState } from "react";
import ButtonData from "./button";
import Detail from "../../assets/images/icons/icn_actions.png";
import edit from "../../assets/images/icons/icn_edit.png";
import Delete from "../../assets/images/icons/icn_delete_blue.png";
import axios from "../../helper/api/api_instance";
import toastr from "toastr";
import FormModal from "../modals/FormModal";
import { Dropdown, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { Base_Route } from "../../helper/constant";
const { Paragraph } = Typography;
const Workout = ({
  title,
  description,
  type,
  code,
  onClickButton,
  loading,
  id,
  selectedWorkout,
  fullWorkout,
  workout,
  setWorkout,
}) => {
  const [recordId, setRecordId] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() =>
            navigate(`${Base_Route}/trufit/add-workout`, {
              state: { id, label: "edit", workout: fullWorkout },
            })
          }
          className="d-flex align-items-center text-decoration-none"
        >
          <img
            src={edit}
            alt="edit"
            className="mb-1"
            style={{ height: "14px" }}
          />
          <span className="mx-2 dropdown-item">Edit</span>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => openDeleteModal(id)}
          className="d-flex align-items-center text-decoration-none"
        >
          <img
            src={Delete}
            alt="delete"
            className="mb-1"
            style={{ height: "15px" }}
          />
          <span className="mx-2 dropdown-item">Delete</span>
        </div>
      ),
    },
  ];

  const openDeleteModal = (id) => {
    setRecordId(id);
    setShow(true);
  };

  const handleDeleteCancel = () => {
    setShow(false);
  };

  const deleteWorkoutHandler = () => {
    setDeleteLoading(true);
    const fd = new FormData();
    fd.append("workout_id", recordId);
    axios
      .post(`delete_workout`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setWorkout(workout.filter((x) => x?.workout_id !== recordId));
          setShow(false);
          setDeleteLoading(false);
        } else {
          toastr.error(res.data.message);
          setDeleteLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setDeleteLoading(false);
      });
  };

  return (
    <>
      <div className="exercise-sec mt-3">
        <div className=" d-flex align-items-center justify-content-between">
          <p className="exercise-heading ellipsis-string">{title}</p>
          {type === "group" ? (
            <></>
          ) : (
            <>
              <div>
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                  placement="bottomRight"
                  arrow
                >
                  <img
                    src={Detail}
                    alt="detail-button"
                    style={{ height: "20px" }}
                  />
                </Dropdown>
              </div>
            </>
          )}
        </div>

        <Paragraph
          className="exercise-desc"
          ellipsis={{
            rows: 4,
            expandable: false,
          }}
        >
          {description}
        </Paragraph>
        <ButtonData
          loading={id === selectedWorkout ? loading : ""}
          type="assign-btn"
          onClick={() => onClickButton(code, id)}
        >
          {type === "group" ? "Join Group" : "Assign Workout"}
        </ButtonData>
      </div>
      <FormModal
        visible={show}
        title={"Delete Workout"}
        onCancel={handleDeleteCancel}
        content={
          <>
            <p>Are you sure! You want to delete the Workout</p>
            <div className="d-flex justify-content-end">
              <div className="d-flex justify-content-between">
                <ButtonData
                  type="border-btn mx-2"
                  onClick={() => setShow(!show)}
                >
                  Cancel
                </ButtonData>
                <ButtonData
                  type="assign-btn"
                  onClick={() => deleteWorkoutHandler()}
                  loading={deleteLoading}
                >
                  ok
                </ButtonData>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default Workout;
